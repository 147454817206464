<template>
    <div class="driversList-styles">
        <MobileHeader :isPageLoaded="isLoading"></MobileHeader>

        <div class="main-content">
            <div class="middle-content">
                <div class="d-flex flex-column flex-sm-row justify-space-between">
                    <div>
                        <p class="user-selected-title mb-0">{{ localStorage.username }}</p>
                        <p class="mb-1 auth-user-title">Drivers</p>
                    </div>
                    <div class="align-self-md-center add-driver-section mt-2 mt-sm-0">
                        <router-link to="/add-driver/driverlist/10/6312-4223f-312e">
                            <v-btn color="#191919" large class="white--text add-driver-btn" width="100%" height="36px">
                                + Add Driver
                            </v-btn>
                        </router-link>
                    </div>
                </div>

                <div v-for="(driver, index) of driversList" :key="driver.auth0_user_id">
                    <v-row class="section-card mt-6 py-3 py-sm-1 px-4 no-gutters">
                        <v-col md="4" cols="12" class="d-flex align-center">
                            <div class="d-flex p-2 align-self-center">
                                <div class="profile-icon" v-if="driver && driver.profile_url && driver.profile_url !== ''">
                                    <v-img :src="driver.profile_url"></v-img>
                                </div>

                                <div class="align-self-center pl-1">
                                    <span class="user-name">
                                        {{ driver.name }}
                                    </span>
                                </div>
                            </div>
                        </v-col>
                        <v-col md="4" cols="6" class="mt-2">
                            <div class="d-flex flex-column">
                                <div class="personal-information-heading">Phone</div>
                                <div class="personal-information">
                                    <v-icon color="#191919" size="16">mdi-phone</v-icon>
                                    <span>({{ driver.country_code }}) {{ formatToPhone(driver.phone) }}</span>
                                </div>
                            </div>
                        </v-col>
                        <br><br>
                        <v-col md="3" cols="6" class="mt-sm-2 mt-2 mt-sm-0">
                            <div class="d-flex flex-column">
                                <div class="personal-information-heading">Date Authorized</div>
                                <div class="personal-information">
                                    <v-icon color="#191919" size="16">mdi-calendar-range
                                    </v-icon>
                                    <span v-if="driver.date_authorized != null">{{ driver.date_authorized |
                                        split_createdAt('date') }}</span>
                                    <span v-if="driver.date_authorized == null"> Not authorized </span>
                                </div>
                            </div>
                        </v-col>
                        <v-col md="1" cols="12" class="d-flex align-end align-md-center justify-end">
                            <div>
                                <v-icon @click="navigateEditDriver(driver.id)" color="#191919" size="24"
                                    class="delete-icon">mdi-square-edit-outline</v-icon>

                                <v-icon color="#191919" size="24" @click="selectDriver(driver.id, index, driver.name)"
                                    @click.stop="confirmationDialog = true"
                                    class="delete-icon ml-3">mdi-trash-can-outline</v-icon>
                            </div>
                        </v-col>
                    </v-row>
                </div>

                <template v-if="isLoading">
                    <template v-for="n in 3">
                        <v-sheet class="pa-3" :key="n">
                            <v-row class="section-card mt-6 py-3 py-sm-1 px-4 no-gutters">
                                <v-col md="4" cols="12" class="d-flex align-center">
                                    <div class="d-flex p-2 align-self-center skeleton-full-width">
                                        <v-skeleton-loader class="skeleton-mini-width" max-width="140" type="list-item"></v-skeleton-loader>
                                    </div>
                                </v-col>
                                <v-col md="4" cols="6" class="mt-2">
                                    <div class="d-flex flex-column">
                                        <div class="personal-information-heading">Phone</div>
                                        <div class="personal-information d-flex">
                                            <v-icon color="#191919" size="16">mdi-phone</v-icon>
                                            <v-skeleton-loader class="skeleton-mini-width" max-width="130"
                                                type="list-item"></v-skeleton-loader>
                                        </div>
                                    </div>
                                </v-col>
                                <br><br>
                                <v-col md="3" cols="6" class="mt-sm-2 mt-2 mt-sm-0">
                                    <div class="d-flex flex-column">
                                        <div class="personal-information-heading">Date Authorized</div>
                                        <div class="personal-information d-flex">
                                            <v-icon color="#191919" size="16">mdi-calendar-range
                                            </v-icon>
                                            <v-skeleton-loader class="skeleton-mini-width" max-width="120"
                                                type="list-item"></v-skeleton-loader>
                                        </div>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-sheet>
                    </template>
                </template>
            </div>
            <h3 class="no-user-msg" v-if="errorStatus">
                {{ errorStatus }}
            </h3>

            <br>
        </div>

        <v-dialog v-model="confirmationDialog" persistent max-width="440">
            <v-card class="pa-6 accept-order-vcard">
                <v-card-title class="confirmation-main-title mb-3 pl-0 pb-1 pt-0 pr-0 d-flex justify-space-between">
                    Please confirm you wish to delete the driver {{ selectedDriver.name }}
                </v-card-title>

                <v-card-actions class="pa-0">
                    <v-spacer></v-spacer>
                    <v-btn class="accept-order-btns rounded-md" width="50%" dense outlined text
                        @click="confirmationDialog = false">
                        Cancel
                    </v-btn>

                    <v-btn class="accept-order-btns white--text rounded-md" dense width="50%" dark color="black"
                        @click="confirmationDialog = false; deleteDriver()">
                        Delete
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- <AppLoading :active.sync="isLoading" /> -->
    </div>
</template>

<style scoped lang="scss">
.driversList-styles {
    @import '../assets/styles/DriversList.scss';

    font-family: 'Outfit', sans-serif;

    .inter-font-family {
        font-family: 'Inter', sans-serif !important;
    }

    .outfit-font-family {
        font-family: 'Outfit', sans-serif !important;
    }
}
</style>

<script>
import axios from 'axios';
import mixin from "../mixins/auth.mixin.js";

import MobileHeader from '../components/mobile-header.vue';

export default {
    name: 'DriversList',
    components: {
        MobileHeader,
    },
    data: () => ({
        authMixins: mixin.methods,
        driversList: [],
        errorStatus: "",
        confirmationDialog: false,
        isLoading: true,
        requestStatus: false,
        selectedDriver: {
            'id': -1,
            'index': -1,
            'name': ''
        }
    }),
    computed: {
        localStorage() {
            return localStorage;
        }
    },
    created() {
    },
    mounted() {
        document.title = "Carrier Portal - Breaker19.app";

        this.loadDrivers();
    },
    filters: {
        split_createdAt: function (value, returnType) {
            if (!value) return '';
            let date_time = value.split('T');
            let date = date_time[0].split('-');
            let time = date_time[1].split('.');

            if (returnType === 'date') {
                let mmddyy = date[1] + "/" + date[2] + "/" + date[0];
                return mmddyy;
            }
            else if (returnType === 'time') return time[0];
            else return date_time[0] + " " + date_time[1];
        },
    },
    methods: {
        formatToPhone(value) {
            const input = (value || '').replace(/\D/g, '').substring(0, 10);
            const areaCode = input.substring(0, 3);
            const middle = input.substring(3, 6);
            const last = input.substring(6, 10);

            if (input.length > 6) { value = `(${areaCode}) ${middle}-${last}`; }
            else if (input.length > 3) { value = `(${areaCode}) ${middle}`; }
            else if (input.length > 0) { value = `(${areaCode}`; }

            return value;
        },
        selectDriver(driverId, index, driverName) {
            this.selectedDriver.id = driverId;
            this.selectedDriver.index = index;
            this.selectedDriver.name = driverName;
        },

        loadDrivers() {
            this.requestStatus = true;

            let config = {
                headers: {
                    "Authorization": localStorage.authToken,
                }
            }

            axios.get(`drivers/list`, config)
                .then(response => {
                    if (response.status === 200) {
                        this.driversList = response.data.data;
                        this.errorStatus = "";
                    }
                    if (response.status === 401) {
                        this.authMixins.clearAuthData();
                        this.$router.push('/signin');
                    }
                    this.isLoading = false;
                    this.requestStatus = false;
                }).catch(async (err) => {
                    console.log(err);
                    if (err.response && err.response.status === 401) {
                        this.authMixins.clearAuthData();
                        this.$router.push('/signin');
                    }
                    this.isLoading = false;
                    this.requestStatus = false;
                    this.errorStatus = "No drivers exist";
                });
        },

        navigateEditDriver(driverId) {
            this.$router.push(`/edit-driver/${driverId}`);
        },

        deleteDriver() {
            let config = {
                headers: {
                    "Authorization": localStorage.authToken,
                }
            }

            axios.delete(`/drivers/delete-driver/${this.selectedDriver.id}`, config)
                .then(response => {
                    if (response.status === 200) {
                        this.driversList.splice(this.selectedDriver.index, 1);
                        this.$toast.info('Driver deleted successfully', {});
                    }
                    else this.$toast.error('Driver delete failed', {});
                }).catch(async (err) => {
                    this.$toast.error('Driver delete failed', {});
                    console.log(err);
                });
        }
    },
    beforeDestroy() {
    }
}
</script>