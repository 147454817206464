<template>
    <v-row style="background-color: #f1f1f1;">
        <v-col style="background-color: #f1f1f1;">

            <div class="regionList-styles">

                <MobileHeader :isPageLoaded="isLoading"></MobileHeader>

                <div class="region-rates-contents">
                    <p class="title1 mb-1">{{ localStorage.username }}</p>
                    <p class="title2">Configure Region and Rates</p>

                    <div class="regions-list">
                        <div class="grid-container">

                            <template v-for="region of regionsList">
                                <div class="grid-item" :key="region.id" v-if="region.listed">
                                    <div class="region">
                                        <div class="d-flex justify-space-around">
                                            <div class="location-map-icon">
                                                <v-img src="../assets/icons/location-map-marker.png" alt=""></v-img>
                                            </div>
                                            <div class="region-detail d-flex flex-column">
                                                <p class="region-name">{{ region.name }}</p>
                                                <p class="coming-soon-txt" v-if="region.is_comming_soon">Coming soon</p>

                                                <div v-if="!region.is_comming_soon"
                                                    class="region-option d-flex align-center justify-space-between">
                                                    {{ region.enabled === true ? 'Enabled' : 'Disabled' }}
                                                    <v-switch inset dense v-model="region.enabled" hideDetails="true"
                                                        @change="enable_disableRegion(region.id, region.enabled)"></v-switch>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="region-btns" v-if="region.enabled">
                                            <template v-if="region.enabled && !region.is_comming_soon">
                                                <router-link class="remove-decoration"
                                                    :to="{ path: `/configure-regions/${region.id}/${region.name}` }">
                                                    <v-btn class="configure-btn white--text" block color="black">Configure
                                                        region and rates</v-btn>
                                                </router-link>
                                            </template>

                                            <template v-if="!region.enabled">
                                                <v-btn class="configure-btn white--text" block color="black"
                                                    :disabled="true">Configure region and rates</v-btn>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </template>

                            <template v-if="isLoading">
                                <template v-for="n in 3">
                                    <div class="grid-item" :key="n">
                                        <div class="region">
                                            <div class="d-flex justify-space-around">
                                                <div class="location-map-icon">
                                                    <v-img src="../assets/icons/location-map-marker.png" alt=""></v-img>
                                                </div>
                                                <div class="region-detail d-flex flex-column">
                                                    <v-skeleton-loader class="skeleton-remove-padding skeleton-mini-width" type="list-item"></v-skeleton-loader>

                                                    <div class="region-option d-flex align-center justify-space-between">
                                                        <v-skeleton-loader class="skeleton-remove-padding skeleton-full-width" type="list-item"></v-skeleton-loader>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="region-btns">
                                                <template>
                                                    <v-skeleton-loader class="skeleton-remove-padding skeleton-full-width skeleton-full-width_btn" type="button"></v-skeleton-loader>
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </template>
                        </div>

                        <h3 v-if="errorStatus != ''" class="no-user-msg">
                            {{ errorStatus }}
                        </h3>

                        <h3 v-if="errorStatus == '' && allRegionsStatus" class="no-user-msg">
                            No region exist
                        </h3>
                    </div>
                </div>
            </div>
        </v-col>

        <!-- <AppLoading :active.sync="isLoading" /> -->
    </v-row>
</template>
  
<style scoped lang="scss">
.regionList-styles {
    @import '../assets/styles/RegionList.scss';

    background-color: #F4F4F4;
    font-family: 'Outfit', sans-serif;

    .inter-font-family {
        font-family: 'Inter', sans-serif !important;
    }

    .outfit-font-family {
        font-family: 'Outfit', sans-serif !important;
    }

}
</style>
    
<script>
import axios from 'axios';
import mixin from "../mixins/auth.mixin.js";

import MobileHeader from '../components/mobile-header.vue';

export default {
    name: 'RegionList',
    components: {
        MobileHeader,
    },
    data: () => ({
        authMixins: mixin.methods,
        windowWidth: 0,
        isLoading: true,
        requestStatus: false,
        regionsList: [],
        errorStatus: "",
        allRegionsStatus: false
    }),
    computed: {
        localStorage() {
            return localStorage;
        }
    },
    created() {
        this.updateWindowWidth();
        window.addEventListener('resize', this.updateWindowWidth);
    },
    mounted() {
        document.title = "Carrier Portal - Breaker19.app";

        this.loadRegions();
    },
    methods: {
        updateWindowWidth() {
            this.windowWidth = window.innerWidth;
        },

        loadRegions() {
            let config = {
                headers: {
                    "Authorization": localStorage.authToken,
                }
            }

            axios.get(`carrier/region/region-listing`, config)
                .then(response => {
                    if (response.status === 200) {
                        this.regionsList = response.data;

                        // for(let region of this.regionsList){
                        //     region.listed = false;
                        // }

                        this.regionsList.sort((a, b) => {
                            return b.enabled - a.enabled;
                        });

                        let listed = this.regionsList.every(region => region.listed === false);
                        this.allRegionsStatus = listed;

                        this.errorStatus = "";
                    }
                    if (response.status === 401) {
                        this.authMixins.clearAuthData();
                        this.$router.push('/signin');
                    }
                    this.isLoading = false;
                }).catch(async (err) => {
                    this.errorStatus = "No regions exist";
                    this.isLoading = false;

                    if (err.response && err.response.status === 401) {
                        this.authMixins.clearAuthData();
                        this.$router.push('/signin');
                    }

                    console.log("err: ", err);
                });
        },

        enable_disableRegion(id, enableStatus) {
            console.log(id, " : ", enableStatus);

            if (this.requestStatus) return;

            this.requestStatus = true;
            let config = {
                headers: {
                    "Authorization": localStorage.authToken,
                }
            };

            let regionData = {
                "enabled": enableStatus
            };

            axios.patch(`carrier/region/enable/${id}`, regionData, config)
                .then(response => {
                    if (response.status === 200) console.log("success: ", response);
                    this.requestStatus = false;
                }).catch(async (err) => {
                    console.log("err: ", err);
                    this.requestStatus = false;
                });
        }
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.updateWindowWidth);
    }
}
</script>
    