<template>
  <div>
    <MobileHeader :isPageLoaded="isLoading"></MobileHeader>

    <div class="shipmentDetails-styles">
      <v-row>
        <v-col>
          <div class="sub-header d-flex align-center">
            <router-link :to="previousPage">
              <div class="d-flex align-center">
                <v-img
                  src="../assets/icons/black-back-arrow.png"
                  alt=""
                ></v-img>
                <p>
                  {{
                    previousPage === "/homepage" ? "Homepage" : "Shipment List"
                  }}
                </p>
                <!-- <p v-if="tabNumber === 10">Shipment Detail</p>
                <p v-if="tabNumber === 11">Shipment Detail (Confirmed)</p>
                <p v-if="tabNumber === 0 || tabNumber === 13">Shipment Details</p> -->
              </div>
            </router-link>
          </div>

          <div
            v-if="tabNumber === 11"
            class="title-info d-flex align-start align-sm-center align-start mb-5"
          >
            <img src="../assets/icons/info-icon.png" />
            <span
              >You have been confirmed as the carrier for this shipment. Your
              selected driver will receive an invite to the shipment on the
              Truck driver app shortly</span
            >
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="8" class="col-12 col-sm-12 col-md-8">
          <!-- v-if="shipmentData" -->
          <div class="shipment-details-section">
            <p class="shipment-details-heading">
              Shipment Details &nbsp;<span
                v-if="shipmentData && shipmentData.cancel_time"
                class="canceled-label"
                >(Canceled)</span
              >
            </p>

            <div class="d-flex flex-column flex-sm-row">
              <div class="left-section d-flex flex-column align-center">
                <div class="location-map">
                  <div
                    id="maps"
                    class="map"
                    style="height: 200px; width: 100%"
                  ></div>

                  <!-- <GmapMap :zoom="7"
                           :options="{ mapTypeControl: false, zoomControl: true, scaleControl: false, streetViewControl: false, rotateControl: false, disableDefaultUi: false }"
                           :center="locationData.center" style="width:100%;  height: 200px;">

                    <GmapMarker :key="index" v-for="(m, index) in locationData.locationMarkers"
                                :position="m.position" :clickable="false" :draggable="false"
                                @click="locationData.center = m.position"></GmapMarker>

                    <DirectionsRenderer :origin="directions.origin"
                                        :destination="directions.destination" :waypoints="directions.waypoints" travelMode="DRIVING"/>

                  </GmapMap> -->
                </div>

                <div
                  class="pickndrop pt-5 d-flex flex-column flex-sm-row align-start"
                >
                  <div
                    class="d-flex flex-row"
                    :class="{ 'skeleton-smallest-width': !firstLocation }"
                  >
                    <div
                      class="pick"
                      :class="{ 'skeleton-full-width': !firstLocation }"
                    >
                      <p class="pickndrop-title">Pick Up</p>
                      <p class="pickndrop-address">
                        {{ firstLocation?.city + ", " + firstLocation?.state }}
                      </p>
                      <div v-if="!firstLocation">
                        <v-skeleton-loader
                          class="skeleton-full-width"
                          type="list-item"
                        ></v-skeleton-loader>
                      </div>

                      <div class="d-flex flex-wrap" v-if="firstLocation">
                        <a
                          target="_blank"
                          :href="getLocationUrl(firstLocation)"
                          class="pickndrop-location remove-decoration"
                          >{{ firstLocation.latitude | limitDecimalPlaces }},
                        </a>
                        <a
                          target="_blank"
                          :href="getLocationUrl(firstLocation)"
                          class="pickndrop-location remove-decoration"
                          >{{ firstLocation.longitude | limitDecimalPlaces }}</a
                        >
                        <a
                          target="_blank"
                          :href="getLocationUrl(firstLocation)"
                          class="pickndrop-location remove-decoration"
                        >
                          <v-icon class="link-icon">mdi-open-in-new</v-icon>
                        </a>
                      </div>
                    </div>

                    <div class="connection-arrow">
                      <v-img
                        src="../assets/icons/forward_arrow.png"
                        alt=""
                      ></v-img>
                    </div>
                  </div>

                  <div
                    class="drop drop-margin"
                    :class="{ 'skeleton-smallest-width': !lastLocation }"
                  >
                    <p class="pickndrop-title">Drop Off</p>
                    <p class="pickndrop-address">
                      {{ lastLocation?.city + ", " + lastLocation?.state }}
                    </p>

                    <template v-if="!lastLocation">
                      <v-skeleton-loader
                        class="skeleton-full-width"
                        type="list-item"
                      ></v-skeleton-loader>
                    </template>

                    <div class="d-flex flex-wrap" v-if="lastLocation">
                      <a
                        target="_blank"
                        :href="getLocationUrl(lastLocation)"
                        class="pickndrop-location remove-decoration"
                        >{{ lastLocation.latitude | limitDecimalPlaces }},
                      </a>
                      <a
                        target="_blank"
                        :href="getLocationUrl(lastLocation)"
                        class="pickndrop-location remove-decoration"
                        >{{ lastLocation.longitude | limitDecimalPlaces }}
                      </a>
                      <a
                        target="_blank"
                        :href="getLocationUrl(lastLocation)"
                        class="pickndrop-location remove-decoration"
                      >
                        <v-icon class="link-icon">mdi-open-in-new</v-icon>
                      </a>
                    </div>
                  </div>
                </div>

                <div class="d-flex date-time-section pl-0 pl-sm-3">
                  <div
                    class="date-section d-flex flex-column"
                    :class="{
                      'skeleton-smallest-width': !shipmentData.pickup_time,
                    }"
                  >
                    <p class="date-time-title">Pickup Date</p>
                    <p class="date-time" v-if="shipmentData.pickup_time">
                      {{
                        formatDate(shipmentData.pickup_time).format(
                          "MM/DD/YYYY"
                        )
                      }}
                    </p>
                    <p class="date-time" v-if="!shipmentData.pickup_time">
                      As soon as possible
                    </p>

                    <template v-if="!shipmentData.pickup_time && isLoading">
                      <v-skeleton-loader
                        class="skeleton-full-width"
                        type="list-item"
                      ></v-skeleton-loader>
                    </template>
                  </div>

                  <div
                    class="time-section d-flex flex-column pickup-time-margin"
                    :class="{
                      'skeleton-smallest-width': !shipmentData.pickup_time,
                    }"
                  >
                    <p class="date-time-title">Pickup Time</p>
                    <p class="date-time" v-if="shipmentData.pickup_time">
                      {{
                        formatDate(shipmentData.pickup_time).format(
                          "h:mm a (z)"
                        )
                      }}
                    </p>
                    <p class="date-time" v-if="!shipmentData.pickup_time">-</p>

                    <template v-if="!shipmentData.pickup_time && isLoading">
                      <v-skeleton-loader
                        class="skeleton-full-width"
                        type="list-item"
                      ></v-skeleton-loader>
                    </template>
                  </div>
                </div>

                <div
                  class="bill-of-lading-section mt-3 pl-0 pl-sm-3"
                  v-if="billOfLading && (tabNumber === 0 || tabNumber === 13)"
                >
                  <p class="mb-0 mt-0">BOL#</p>
                  <div>
                    <a
                      class="bill-of-lading-link"
                      :href="billOfLading.links.original"
                      target="_blank"
                    >
                      {{ billOfLading.name | ladingBillLink }}
                      <v-icon class="link-icon">mdi-open-in-new</v-icon>
                    </a>
                  </div>
                </div>
                <div
                  class="bill-of-lading-section mt-3 pl-0 pl-sm-3"
                  v-if="
                    rateConfirmation && (tabNumber === 0 || tabNumber === 13)
                  "
                >
                  <p class="mb-0 mt-0">Rate Confirmation#</p>
                  <div>
                    <a
                      class="bill-of-lading-link"
                      :href="rateConfirmation.links.original"
                      target="_blank"
                    >
                      {{ rateConfirmation.name | ladingBillLink }}
                      <v-icon class="link-icon">mdi-open-in-new</v-icon>
                    </a>
                  </div>
                </div>
              </div>

              <div class="right-section d-flex flex-column">
                <div class="d-flex align-center pt-3 mb-3">
                  <div>
                    <p class="title-val">
                      #
                      {{
                        [shipmentData.turvo_id, shipmentData.custom_id]
                          .filter((val) => !!val)
                          .join(" / ")
                      }}
                    </p>
                  </div>
                </div>
                <div
                  :class="{
                    'd-flex': tabNumber === 0 || tabNumber === 13,
                    'd-none': tabNumber === 10,
                  }"
                >
                  <div>
                    <p class="detail-title">Price (what you will make)</p>
                    <p class="price">
                      {{
                        shipmentData?.quote_result?.carrier_cut
                          ? parseFloat(shipmentData?.quote_result?.carrier_cut).toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })
                          : null
                      }}
                    </p>
                  </div>
                </div>
                <div class="d-flex align-center pt-3 mb-3">
                  <div class="load-type">
                    <p class="detail-title">Load Type</p>
                    <p class="title-val">
                      {{
                        shipmentData.region_service
                          ? shipmentData.region_service.service.name
                          : ""
                      }}
                    </p>

                    <template v-if="!shipmentData.region_service && isLoading">
                      <v-skeleton-loader
                        class="skeleton-full-width"
                        type="list-item"
                      ></v-skeleton-loader>
                    </template>
                  </div>
                  <div class="pickup-time-margin" v-if="shipmentData.driver">
                    <p class="detail-title">Driver</p>
                    <p class="detail-price">{{ shipmentData.driver.name }}</p>

                    <template v-if="!shipmentData.driver && isLoading">
                      <v-skeleton-loader
                        class="skeleton-full-width"
                        type="list-item"
                      ></v-skeleton-loader>
                    </template>
                  </div>
                </div>

                <div class="my-2 d-flex align-center">
                  <div class="load-type">
                    <p class="detail-title">Truck type</p>
                    <p class="title-val">
                      {{
                        shipmentData.region_service
                          ? shipmentData.region_service.value
                            ? shipmentData.region_service.value.name
                            : ""
                          : ""
                      }}
                    </p>

                    <template v-if="!shipmentData.region_service && isLoading">
                      <v-skeleton-loader
                        class="skeleton-full-width"
                        type="list-item"
                      ></v-skeleton-loader>
                    </template>
                  </div>
                  <div
                    style="width: 30%"
                    class="pickup-time-margin"
                    :class="{
                      'skeleton-full-width': !shipmentData.pickup_time,
                    }"
                  >
                    <template
                      v-if="
                        shipmentData.region_service &&
                        shipmentData.region_service.value &&
                        shipmentData.region_service.value.links &&
                        shipmentData.region_service.value.links.original
                      "
                    >
                      <v-img
                        :src="shipmentData.region_service.value.links.original"
                      ></v-img>
                    </template>

                    <template v-if="!shipmentData.region_service && isLoading">
                      <v-skeleton-loader
                        class="skeleton-full-width"
                        max-height="40"
                        type="image"
                      ></v-skeleton-loader>
                    </template>
                  </div>
                </div>
                <div class="my-2 d-flex align-center gap-10">
                  <div class="load-type">
                    <p class="detail-title">Accessories</p>
                    <template
                      v-if="
                        shipmentData.region_service &&
                        shipmentData.region_service.value
                      "
                    >
                      <div>
                        <p class="desc">
                          {{
                            shipmentData.truck_accessories
                              .map((accessory) => accessory.name)
                              .join(", ")
                          }}
                        </p>
                      </div>

                      <div
                        class="detail-price"
                        v-if="
                          !shipmentData.truck_accessories ||
                          shipmentData.truck_accessories.length === 0
                        "
                      >
                        No accessories
                      </div>
                    </template>

                    <template v-if="!shipmentData.region_service && isLoading">
                      <v-skeleton-loader
                        class="skeleton-full-width"
                        type="list-item"
                      ></v-skeleton-loader>
                    </template>
                  </div>

                  <div
                    style="height: 100%"
                    class="pickup-time-margin"
                    :class="{
                      'skeleton-smallest-width': !shipmentData.pickup_time,
                    }"
                  >
                    <p class="detail-title">Insurance Limit</p>
                    <p v-if="shipmentData.insurance_limit" class="detail-price">
                      ${{ shipmentData.insurance_limit.toLocaleString() }}
                    </p>
                    <p
                      v-if="!shipmentData.insurance_limit && !isLoading"
                      class="detail-price"
                    >
                      No limit set
                    </p>

                    <template v-if="!shipmentData.insurance_limit && isLoading">
                      <v-skeleton-loader
                        class="skeleton-full-width"
                        type="list-item"
                      ></v-skeleton-loader>
                    </template>
                  </div>
                </div>

                <div class="mb-2 mt-3">
                  <p class="detail-title">Notes</p>
                  <p class="desc" v-if="shipmentData.notes">
                    {{ shipmentData.notes }}
                  </p>
                  <p class="desc" v-if="!shipmentData.notes">No notes</p>

                  <template v-if="!shipmentData.notes && isLoading">
                    <v-skeleton-loader
                      class="skeleton-full-width"
                      type="list-item"
                    ></v-skeleton-loader>
                  </template>
                </div>

                <div>
                  <p class="detail-title">Pictures</p>
                  <template v-if="!pictureList || pictureList.length === 0">
                    <p class="no-field-txt">No pictures</p>
                  </template>
                  <div class="shipment-pics">
                    <template v-for="(image, index) in pictureList">
                      <div :key="image.id">
                        <img
                          class="shipment-pic"
                          @click="zoomImage(image.links.original, index)"
                          @click.stop="imageDialog = true"
                          :src="image.links.original"
                        />
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-col>

        <!-- //////////////////////// Right Model 0 (Approved shipment detail) ////////////////////////// -->
        <!-- <v-col cols="4" class="col-12 col-sm-12 col-md-4" v-if="tabNumber === -1">
            <div class="confirmation-section">
                <span class="title">Accept This Order?</span>
                <div class="d-flex justify-space-between pt-2">
                    <div style="width: 70%;">
                        <v-btn @click="updateShipmentStatus(true)"
                            class="info-btns white--text green">Accept</v-btn>
                    </div>

                    <div style="width: 70%;">
                        <v-btn @click="updateShipmentStatus(false)" class="info-btns red--text"
                            outlined>Reject</v-btn>
                    </div>
                </div>
            </div>
        </v-col> -->

        <!-- //////////////////////// Right Model 1 (Pending shipment detail) ////////////////////////// -->
        <v-col
          cols="4"
          class="col-12 col-sm-12 col-md-4"
          v-if="tabNumber === 10"
        >
          <div class="pa-6 accept-order-vcard confirmation-section">
            <div
              class="accept-order-main-title pl-0 pb-1 pt-0 pr-0 d-flex justify-space-between"
            >
              Driver
            </div>

            <div class="accept-order-detail">
              You must assign a driver before you can accept an order. You must
              have added your driver in the drivers tab for them to appear as an
              option. Please note that by accepting this shipment, you are
              agreeing to the rate shown.
            </div>

            <div v-if="driversList">
              <span class="accept-order">Choose driver</span>
              <v-select
                :items="driversList"
                @input="assignDriver"
                v-model="selectedDriver"
                item-value="id"
                item-text="name"
                color="grey"
                background-color="#F4F4F4"
                label="Select a driver"
                append-icon="mdi-chevron-down-box"
                height="10px"
                return-object
                solo
                dense
                flat
              >
              </v-select>
            </div>

            <div class="add-driver-section d-flex flex-column">
              <p class="mb-0 add-driver-price-title">
                Price (what you will make)
              </p>
              <p class="add-driver-price">
                {{
                  shipmentData?.quote_result?.carrier_cut
                    ? parseFloat(shipmentData?.quote_result?.carrier_cut).toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                    })
                    : null
                }}
              </p>
            </div>

            <h5 class="mt-1 mb-1" v-if="requestStatus">
              Assigning new driver ...
            </h5>

            <div class="d-flex justify-space-between pt-2">
              <div style="width: 70%">
                <v-btn
                  class="info-btns white--text green"
                  @click.stop="
                    saveOrderStatus(true);
                    confirmDialog = true;
                  "
                  :disabled="!driverAssigned"
                  >Accept
                </v-btn>
              </div>

              <div style="width: 70%">
                <v-btn
                  class="info-btns red--text"
                  @click.stop="
                    saveOrderStatus(false);
                    confirmDialog = true;
                  "
                  :disabled="!driverAssigned"
                  outlined
                  >Reject
                </v-btn>
              </div>
            </div>
          </div>
        </v-col>

        <!-- //////////////////////// Right Model 2 (Active shipment detail) ////////////////////////// -->
        <v-col
          cols="4"
          class="col-12 col-sm-12 col-md-4"
          v-if="tabNumber === 0"
        >
          <div class="pa-6 accept-order-vcard confirmation-section">
            <div
              class="accept-order-main-title pl-0 pb-1 pt-0 pr-0 d-flex justify-space-between"
            >
              Driver
            </div>

            <div v-if="driversList">
              <span class="accept-order">Change driver</span>
              <v-select
                :items="driversList"
                @input="selectDriver"
                v-model="selectedDriver"
                item-value="id"
                item-text="name"
                color="grey"
                background-color="#F4F4F4"
                label="Select a driver"
                append-icon="mdi-chevron-down-box"
                height="10px"
                hide-details="true"
                return-object
                solo
                dense
                flat
              >
              </v-select>

              <p class="error-msg" v-if="assignDriverMsg !== ''">
                {{ assignDriverMsg }}
              </p>
            </div>

            <div
              class="add-driver-section clickable mb-3 d-flex align-center mt-3"
            >
              <router-link
                class="remove-decoration d-flex"
                :to="{
                  path: `/add-driver/shipDetail/${tabNumber}/${shipmentID}`,
                }"
              >
                <v-icon>mdi-plus-circle-outline</v-icon>
                <span class="ml-2">Add new driver</span>
              </router-link>
            </div>

            <div class="d-flex justify-space-between pt-2">
              <div style="width: 100%" @click.stop="confirmDialog = true">
                <v-btn class="change-driver-btn red--text" outlined>
                  {{
                    driverSelectedBtn === "" ? "Assign Driver" : "Change driver"
                  }}
                </v-btn>
              </div>
            </div>
          </div>
        </v-col>

        <!-- //////////////////////// Right Model 3 (Completed shipment detail) ////////////////////////// -->
        <v-col
          cols="4"
          class="col-12 col-sm-12 col-md-4"
          v-if="tabNumber === 13"
        >
          <div class="pa-6 accept-order-vcard confirmation-section">
            <div
              class="accept-order-main-title pl-0 pb-1 pt-0 pr-0 d-flex justify-space-between"
            >
              Driver
            </div>
            <p class="driver-name">
              {{
                shipmentData.driver
                  ? shipmentData.driver.name
                  : "Driver not assigned"
              }}
            </p>

            <div class="d-flex">
              <div class="date-completed">
                <p class="mb-2 date-completed-title">Date Completed</p>
                <p
                  class="mb-0 date-completed-val"
                  v-if="shipmentData.completed_time"
                >
                  {{
                    formatDate(shipmentData.completed_time).format("MM/DD/YYYY")
                  }}
                </p>

                <p
                  class="mb-0 date-completed-val"
                  v-if="!shipmentData.completed_time"
                >
                  Not completed
                </p>
              </div>

              <div class="pl-7 date-completed">
                <p class="mb-2 date-completed-title">Time Completed</p>
                <p
                  class="mb-0 date-completed-val"
                  v-if="shipmentData.completed_time"
                >
                  {{
                    formatDate(shipmentData.completed_time).format("h:mm a (z)")
                  }}
                </p>

                <p
                  class="mb-0 date-completed-val"
                  v-if="!shipmentData.completed_time"
                >
                  Not completed
                </p>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>

    <v-dialog v-model="imageDialog" persistent max-width="840">
      <v-card class="pa-10 accept-order-vcard d-flex flex-column align-center">
        <div class="">
          <span class="closeModalIcon" @click="imageDialog = false">
            &times;
          </span>

          <v-zoomer style="width: 720px; height: 400px">
            <img
              :src="selectedPicture"
              style="object-fit: contain; width: 100%; height: 100%"
            />
          </v-zoomer>

          <div class="arrowContainer">
            <a class="arrowIcon" @click="handlePrevImage"> ❮ </a>
            <a class="arrowIcon" @click="handleNextImage"> ❯ </a>
          </div>
        </div>

        <!-- <v-card-actions class="pa-0">
          <v-btn
            class="accept-order-btns rounded-md"
            width="50%"
            dense
            outlined
            text
            @click="imageDialog = false"
          >
            Close
          </v-btn>

        </v-card-actions> -->
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirmDialog" persistent max-width="440">
      <v-card class="pa-6 accept-order-vcard">
        <v-card-title
          class="confirm-dialog-title pl-0 pb-1 pt-0 pr-0 d-flex justify-space-between"
        >
          <template v-if="tabNumber === 0">
            Are you sure you want to change the driver?
          </template>

          <template v-if="tabNumber === 10">
            {{
              orderStatus
                ? "Are you sure you want to accept the order?"
                : "Are you sure you want to reject the order ? "
            }}
          </template>
        </v-card-title>

        <v-card-actions class="pa-0">
          <v-spacer></v-spacer>
          <v-btn
            class="accept-order-btns rounded-md"
            width="50%"
            dense
            outlined
            text
            @click="confirmDialog = false"
          >
            No
          </v-btn>

          <v-btn
            v-if="tabNumber === 0"
            class="accept-order-btns white--text rounded-md"
            dense
            width="50%"
            dark
            color="black"
            @click="
              confirmDialog = false;
              changeDriver();
            "
          >
            Yes
          </v-btn>

          <v-btn
            v-if="tabNumber === 10 && orderStatus"
            class="accept-order-btns white--text rounded-md"
            dense
            width="50%"
            dark
            color="black"
            @click="
              confirmDialog = false;
              orderAccept(true);
            "
          >
            Yes
          </v-btn>
          <v-btn
            v-if="tabNumber === 10 && !orderStatus"
            class="accept-order-btns white--text rounded-md"
            dense
            width="50%"
            dark
            color="black"
            @click="
              confirmDialog = false;
              orderAccept(false);
            "
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="ftlWarning" persistent max-width="440">
      <v-card class="pa-6 accept-order-vcard">
        <v-card-title
          class="confirm-dialog-title pl-0 pb-1 pt-0 pr-0 d-flex justify-space-between"
        >
          Warning!
        </v-card-title>
        <v-card-text class="pl-0">
          This is a FTL quote, <br />The Driver will be notified once the buyer
          accepts the quote.
        </v-card-text>

        <v-card-actions class="pa-0">
          <v-spacer />

          <v-btn
            class="accept-order-btns white--text rounded-md"
            dense
            width="50%"
            dark
            color="black"
            @click="dismissFTLWarning"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- <AppLoading :active.sync="isLoading" /> -->
  </div>
</template>

<style scoped lang="scss">
.shipmentDetails-styles {
  @import "../assets/styles/ShipmentDetails.scss";

  font-family: "Inter", sans-serif;
  padding: 23px;

  @media screen and(max-width: 600px) {
    padding: 15px;
  }
}
</style>

<script>
// import DirectionsRenderer from "@/components/DirectionsRenderer";
import { convertTimezone } from "@/helpers/timeHelper";
import axios from "axios";

import MobileHeader from "../components/mobile-header.vue";
import mixin from "../mixins/auth.mixin.js";
import moment from "moment";

export default {
  name: "ShipmentDetails",
  components: {
    MobileHeader,
    // DirectionsRenderer
  },
  data: () => ({
    map: null,
    authMixins: mixin.methods,
    tabNumber: 0,
    shipmentID: -1,
    selectedDriver: null,
    shipmentData: {},
    firstLocation: {},
    lastLocation: {},
    isLoading: true,
    imageDialog: false,
    confirmDialog: false,
    ftlWarning: false,
    currentPictureIndex: -1,
    selectedPicture: "",
    driversList: [],
    driverSelectedBtn: "",
    previousPage: "",
    orderStatus: false,
    assignDriverMsg: "",
    timezone: "",

    locationData: {
      center: {
        lat: 40.7128,
        lng: -74.006,
      },
      locationMarkers: [
        { position: { lat: 40.7128, lng: -74.006 } }, // New York
        { position: { lat: 34.0522, lng: -118.2437 } }, // Los Angeles
      ],
      locPlaces: [],
      existingPlace: null,
    },
    directions: {
      origin: "",
      destination: "",
      waypoints: [],
      travelMode: "DRIVING",
      optimizeWaypoints: true,
    },
    requestStatus: false,
  }),
  computed: {
    pictureList() {
      return this.shipmentData.documents.filter(
        (document) => document.type === "image"
      );
    },

    driverAssigned() {
      return !!this.shipmentData.driver_id;
    },

    billOfLading() {
      let billOfLading = null;
      const documents = Array.isArray(this.shipmentData.documents)
        ? this.shipmentData.documents
        : [];

      for (const document of documents) {
        if (document.type === "bill_of_lading") {
          billOfLading = document;

          break;
        }
      }

      return billOfLading;
    },
    rateConfirmation() {
      let rateConfirmation = null;
      const documents = Array.isArray(this.shipmentData.documents)
        ? this.shipmentData.documents
        : [];

      for (const document of documents) {
        if (document.type === "carrier_rate_confirmation") {
          rateConfirmation = document;

          break;
        }
      }

      return rateConfirmation;
    },
  },
  async created() {
    this.tabNumber = +this.$route.params.selectedTabNumber;
    this.shipmentID = this.$route.params.shipmentID;
    this.previousPage = this.$route.params.previousPage;

    if (this.previousPage === "homepage") this.previousPage = "/homepage";
    else this.previousPage = "/shipment-list";
  },

  async mounted() {
    document.title = "Carrier Portal - Breaker19.app";
    this.timezone = localStorage.timezone;
    await this.loadShipmentDetail();
    const mapInterval = setInterval(() => {
      if (typeof google != undefined) {
        this.initMap();
      } else {
        clearInterval(mapInterval);
      }
    }, 1000);
  },

  filters: {
    split_using_space: function (value, returnType) {
      if (!value) return "";
      value = value.split(" ");
      if (returnType === "date") {
        let splitted_date = value[0].split("-");
        let reformat =
          splitted_date[1] + "/" + splitted_date[2] + "/" + splitted_date[0];
        return reformat;
      } else if (returnType === "time") return value[1];
      else return value[0] + " " + value[1];
    },

    limitDecimalPlaces(value) {
      if (!value) return "0.00000";
      return parseFloat(value).toFixed(5);
    },

    toTimezone(time) {
      if (!time) return;
      return convertTimezone(time, localStorage.timezone);
    },

    ladingBillLink(name) {
      if (!name) return;
      let separate = name.split(".");
      let bol_name = separate[0];

      let bol_name_separate = bol_name.split("-");
      let ids = "";
      for (let b = 1; b < bol_name_separate.length; b++) {
        ids += bol_name_separate[b];
        if (b < bol_name_separate.length - 1) ids += "-";
      }
      return ids;
    },
  },

  methods: {
    async initMap() {
      if (typeof google == undefined) {
        return;
      }
      const { AdvancedMarkerElement, PinElement } =
        await window.google.maps.importLibrary("marker");
      var map = this.map;
      if (this.map == null) {
        map = new window.google.maps.Map(document.getElementById("maps"), {
          zoom: 4,
          mapId: "2993bb26d878ba6a",
          center: new window.google.maps.LatLng(
            this.locationData.center.lat,
            this.locationData.center.lng
          ),
          streetViewControl: false,
          mapTypeControl: false,
          zoomControl: true,
          fullscreenControl: true,
          // mapTypeId: google.maps.MapTypeId.ROADMAP
        });
        this.map = map;

        let bounds = new window.google.maps.LatLngBounds();
        this.locationData.locationMarkers.forEach((markerInfo, index) => {
          bounds.extend(
            new window.google.maps.LatLng(
              markerInfo.position.lat.toString(),
              markerInfo.position.lng.toString()
            )
          );
          const pinBackground = new PinElement({
            background:
              index == 0
                ? "white"
                : index == this.locationData.locationMarkers.length - 1
                ? "black"
                : "black",
            borderColor:
              index == 0
                ? "black"
                : index == this.locationData.locationMarkers.length - 1
                ? "black"
                : "black",
            glyphColor: "#454343",
            scale: 0.7,
          });
          const markerContent = document.createElement("div");
          const borderColor =
            index == 0
              ? "black"
              : index == this.locationData.locationMarkers.length - 1
              ? "red"
              : "blue";
          markerContent.style.backgroundColor = "white";
          markerContent.style.padding = "4px 12px";
          markerContent.style.whiteSpace = "no";
          markerContent.style.border = "1px solid " + borderColor;
          markerContent.style.borderRadius = "50px";
          markerContent.textContent =
            index == 0
              ? "Pickup Location"
              : index == this.locationData.locationMarkers.length - 1
              ? "Dropoff Location"
              : "Stop " + index.toString();

          const combinedContent = document.createElement("div");

          combinedContent.style.display = "flex";
          combinedContent.style.flexDirection = "column";
          combinedContent.style.alignItems = "center";
          combinedContent.style.gap = "5px";
          combinedContent.style.transform = "translateY(26px)";
          combinedContent.appendChild(pinBackground.element);
          combinedContent.appendChild(markerContent);
          new AdvancedMarkerElement({
            map,
            position: new window.google.maps.LatLng(
              markerInfo.position.lat,
              markerInfo.position.lng
            ),
            content: combinedContent,
          });
        });
        this.map.fitBounds(bounds);
        let directionsService = new window.google.maps.DirectionsService();
        directionsService.route(
          {
            origin: this.directions.origin,
            destination: this.directions.destination,
            waypoints: this.directions.waypoints.map((waypoint) => ({
              location: waypoint,
            })),
            travelMode: this.directions.travelMode,
          },
          (response, status) => {
            if (status !== "OK") return;
            //directionsRenderer.setDirections(response);
            let route = response.routes[0];
            let routePath = route.overview_path;
            let routePolyline = new window.google.maps.Polyline({
              path: routePath,
              geodesic: true,
              strokeColor: "#FF0000",
              strokeOpacity: 1.0,
              strokeWeight: 2,
            });
            routePolyline.setMap(this.map);
          }
        );
      }
    },
    formatDate(date) {
      let tz = "Etc/GMT";

      if (localStorage.timezone) {
        tz = localStorage.timezone;
      }

      return moment(date).tz(tz);
    },
    selectDriver(selectedDriver) {
      this.selectedDriver = selectedDriver;
    },

    assignDriver(selectedDriver) {
      this.selectedDriver = selectedDriver;
      this.changeDriver();
    },

    saveOrderStatus(status) {
      this.orderStatus = status;
    },

    zoomImage(img, index) {
      this.selectedPicture = img;
      this.currentPictureIndex = index;
      // this.selectedPicture = require(`../assets/images/${this.selectedPicture}`);
    },

    handleNextImage() {
      if (this.currentPictureIndex == this.pictureList.length - 1) return;
      this.currentPictureIndex = this.currentPictureIndex + 1;
      this.selectedPicture =
        this.pictureList[this.currentPictureIndex].links.original;
    },

    handlePrevImage() {
      if (this.currentPictureIndex == 0) return;
      this.currentPictureIndex = this.currentPictureIndex - 1;
      this.selectedPicture =
        this.pictureList[this.currentPictureIndex].links.original;
    },

    async loadShipmentDetail() {
      if (!this.requestStatus) {
        this.requestStatus = true;

        let config = {
          headers: {
            Authorization: localStorage.authToken,
          },
        };

        await axios
          .get(`carriers/shipments/${this.shipmentID}`, config)
          .then((response) => {
            if (response.status === 200) {
              this.shipmentData = response.data;
              this.errorStatus = "";

              this.locationData.center.lat =
                +this.shipmentData.route[0].latitude;
              this.locationData.center.lng =
                +this.shipmentData.route[0].longitude;

              this.locationData.locationMarkers = [];
              this.shipmentData.route.map((location) => {
                this.locationData.locationMarkers.push({
                  position: {
                    lat: location.latitude,
                    lng: location.longitude,
                  },
                });
              });

              this.firstLocation = this.shipmentData.route[0];
              this.lastLocation = this.shipmentData.route.at(-1);
              this.directions.origin =
                +this.shipmentData.route[0].latitude +
                "," +
                +this.shipmentData.route[0].longitude;
              this.directions.destination =
                +this.shipmentData.route[this.shipmentData.route.length - 1]
                  .latitude +
                "," +
                +this.shipmentData.route[this.shipmentData.route.length - 1]
                  .longitude;
              this.directions.waypoints = this.shipmentData.route
                ?.slice(1, -1)
                .map(
                  (location) => `${location.latitude},${location.longitude}`
                );
              this.isLoading = false;
            }
            if (response.status === 401) {
              this.authMixins.clearAuthData();
              this.$router.push("/signin");
            }
            this.requestStatus = false;
            this.isLoading = false;
          })
          .catch(async (err) => {
            this.requestStatus = false;
            this.isLoading = false;
            this.errorStatus = "No shipment detail exist";

            if (err.response && err.response.status === 401) {
              this.authMixins.clearAuthData();
              this.$router.push("/signin");
            }

            console.log(err);
          });

        this.loadDrivers();
      }
    },

    async loadDrivers() {
      this.driversList = [];

      let config = {
        headers: {
          Authorization: localStorage.authToken,
        },
      };

      await axios
        .get(`drivers/list`, config)
        .then((response) => {
          if (response.status === 200) {
            let tempDrivers = response.data.data;
            this.driversList = tempDrivers;
            this.selectDriver(this.shipmentData.driver);
            // this.assignDriver(this.shipmentData.driver);
          }
        })
        .catch(async (err) => {
          console.log("drivers: ", err);
          this.driversList.push(this.shipmentData.driver);
          this.selectDriver(this.shipmentData.driver);
          // this.assignDriver(this.shipmentData.driver);
        });
    },

    updateShipmentStatus(status) {
      if (!this.requestStatus) {
        this.requestStatus = true;

        let config = {
          headers: {
            Authorization: localStorage.authToken,
          },
        };

        let orderStatus = {
          status: status,
        };

        axios
          .patch(`carriers/${this.shipmentID}/status`, orderStatus, config)
          .then((response) => {
            if (response.status === 200) {
              this.errorStatus = "";
              this.tabNumber = -1;
            }

            this.errorStatus = "Status failed to update";
            this.requestStatus = false;
          })
          .catch(async (err) => {
            this.requestStatus = false;
            this.errorStatus = "Order status update failed";
            console.log(err);
          });
      }
    },

    changeDriver() {
      if (!this.selectedDriver) {
        this.assignDriverMsg = "Select a driver";
        return;
      }

      if (!this.requestStatus) {
        this.requestStatus = true;
        this.assignDriverMsg = "";

        let config = {
          headers: {
            Authorization: localStorage.authToken,
          },
        };

        let driver = {
          driver_id: this.selectedDriver.id,
        };

        axios
          .patch(
            `carriers/shipments/${this.shipmentData.id}/assign-driver`,
            driver,
            config
          )
          .then((response) => {
            if (response.status === 200) {
              this.errorStatus = "";
              this.shipmentData.driver_id = response.data.driver_id;
              this.shipmentData.driver = response.data.driver;
              this.selectedDriver = response.data.driver;
              // this.assignDriver(this.shipmentData.driver);
            }
            this.$toast.info("Driver changed successfully", {});
            this.requestStatus = false;
          })
          .catch(async (err) => {
            this.requestStatus = false;
            this.$toast.info("Driver change failed", {});
            console.log(err);
          });
      }
    },

    orderAccept(status) {
      if (!this.requestStatus) {
        this.requestStatus = true;

        let config = {
          headers: {
            Authorization: localStorage.authToken,
          },
        };

        let driver = {
          driver_id: this.selectedDriver.id,
        };

        let orderUrl = "";
        if (status)
          orderUrl = `carriers/shipments/${this.shipmentData.id}/accept`;
        else orderUrl = `carriers/shipments/${this.shipmentData.id}/decline`;

        axios
          .patch(orderUrl, driver, config)
          .then((response) => {
            if (response.status === 200) {
              this.driverAssigned = false;
              if (status) {
                this.$toast.info("Order accepted successfully", {});

                if (this.shipmentData.region_service.service.flags & (1 << 0)) {
                  this.ftlWarning = true;
                }
              } else {
                this.$toast.info("Order rejected successfully", {});
              }
              if (this.previousPage !== "" && !this.ftlWarning)
                this.$router.push(this.previousPage);
            }

            this.requestStatus = false;
          })
          .catch(async (err) => {
            this.requestStatus = false;
            this.$toast.error("Order update failed", {});
            console.log(err);
          });
      }
    },

    dismissFTLWarning() {
      this.ftlWarning = false;
      if (this.previousPage !== "") this.$router.push(this.previousPage);
    },

    getLocationUrl(location) {
      if (location.source === 2) {
        const address = [
          location.line1,
          location.line2,
          location.city,
          location.state,
          location.zip,
        ]
          .filter((segment) => segment && !segment.startsWith("_void_"))
          .join(", ");

        return `https://maps.google.com/maps?z=11&t=m&q=loc:${encodeURIComponent(
          address
        )}`;
      }

      return `https://maps.google.com/maps?z=11&t=m&q=loc:${location.latitude}+${location.longitude}`;
    },
  },
};
</script>
