<template>
    <v-row class="ma-0">
        <v-col class="pa-0">
            <div class="forgotpassword-styles">
                <div class="forgot-header">
                    <div
                        class="d-sm-flex d-md-flex justify-sm-center justify-md-center justify-lg-center justify-xl-center">
                        <router-link class="remove-routerlink-style" to="/sigin">
                            <div class="header-txt-content d-flex align-start align-sm-center">
                                <img class="back-icon" src="../assets/icons/back-icon.png">
                                <span class="forgot-pwd-txt ml-7 mr-2 ml-sm-2">Forgot Password</span>
                            </div>
                        </router-link>
                    </div>

                    <div class="main-content">
                        <div class="middle-content inter-font-family">
                            <p class="info-title">
                                We will send a reset password link to your registered e-mail address.
                            </p>

                            <v-form @submit.prevent="resetPassword()">
                                <div class="input-form-content">
                                    <label>
                                        E-mail Address
                                    </label>
                                    <v-text-field class="forgot-input mb-1" v-model="email" @input="enterEmail()"
                                        placeholder="e.g. BillyBob@gmail.com" solo dense background-color="#F4F4F4" flat
                                        hideDetails="true"></v-text-field>
                                    <p class="error-msg mb-3">{{ errorEmailMsg }}</p>

                                    <v-btn type="submit" class="reset-btn white--text outfit-font-family mb-2" block
                                        color="black">Reset
                                        Password</v-btn>

                                    <p :class="{ 'error-msg': responseType === 0, 'success-msg': responseType === 200 }">{{
                                        apiResponseMsg }}</p>
                                </div>
                            </v-form>
                        </div>
                    </div>
                </div>
            </div>
        </v-col>
    </v-row>
</template>
  
<style scoped lang="scss">
.forgotpassword-styles {
    @import '../assets/styles/ForgotPassword.scss';

    font-family: 'Outfit', sans-serif;

    .inter-font-family {
        font-family: 'Inter', sans-serif;
    }

    .outfit-font-family {
        font-family: 'Outfit', sans-serif;
    }
}
</style>
    
<script>
import axios from 'axios';

export default {
    name: 'ForgotPassword',

    data: () => ({
        requestStatus: false,
        errorEmailMsg: '',
        apiResponseMsg: '',
        responseType: 0,
        email: '',
    }),
    mounted() {
        document.title = "Carrier Portal - Breaker19.app";
    },
    methods: {
        enterEmail() {
            if (this.email === '') this.errorEmailMsg = 'Please enter email';
            else this.errorEmailMsg = '';
        },

        resetPassword() {
            if (this.email === '') {
                this.errorEmailMsg = 'Please enter email';
                return;
            }

            if (!this.requestStatus) {
                this.requestStatus = true;

                axios.post(`carrier/forgot-password`, { 'email': this.email })
                    .then(response => {
                        this.responseType = response.status;

                        if (response.status === 200) {
                            this.apiResponseMsg = response.data.message;
                            this.$toast.success(this.apiResponseMsg, {});
                            this.$router.push('/signin');
                        }
                        else {
                            this.apiResponseMsg = response.data.message;
                            setTimeout(() => {
                                this.apiResponseMsg = '';
                            }, 3000);
                        }
                        this.requestStatus = false;
                    }).catch(async (err) => {
                        console.log(err);
                        if (err.response.status == 422)
                            this.apiResponseMsg = 'Invalid email';
                        else 
                            this.apiResponseMsg = 'Something went wrong';
                        setTimeout(() => {
                            this.apiResponseMsg = '';
                        }, 3000);
                        this.requestStatus = false;
                    });
            }
        }
    }
}
</script>