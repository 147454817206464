<template>
  <div>
    <MobileHeader @route-clicked="saveRoute" :isFormDirty="isDirty" :isPageLoaded="isLoading"></MobileHeader>

    <v-row style="background-color: #f1f1f1;">
      <v-col style="background-color: #f1f1f1;">

        <div v-if="windowWidth > 600" class="configureRegionRates-styles">
          <div class="header-txt-content align-center mb-7">
            <!-- <router-link class="remove-decoration d-flex" to="/region-list"> -->
            <img class="back-icon" @click="saveRoute('/region-list');" src="../assets/icons/black-back-arrow.png">
            <span class="back-btn-txt black--text" @click="saveRoute('/region-list');">Back to region and rates</span>
            <!-- </router-link> -->
          </div>

          <div class="main-content">
            <!-- =========================== Sub Header ============================== -->
            <div class="header-section d-flex">
              <div class="header-section-left">
                <p class="region-title mb-2" v-if="!isLoading">{{ localStorage.username }}</p>
                <p class="region-title mb-2" v-if="isLoading">
                  <v-skeleton-loader class="skeleton-mini-width skeleton-remove-padding" max-width="120"
                    type="list-item"></v-skeleton-loader>
                </p>

                <div class="location-map-icon d-flex align-start">
                  <v-img src="../assets/icons/location-map-marker.png" alt=""></v-img>
                  <p v-if="!isLoading">{{ regionName }}</p>
                  <div class="skeleton-full-width" v-if="isLoading">
                    <v-skeleton-loader class="skeleton-mini-width skeleton-remove-padding" max-width="120"
                      type="list-item"></v-skeleton-loader>
                  </div>
                </div>

                <p v-if="!isLoading" class="title-info">Configure your rates for {{ regionName }} region</p>
              </div>

              <div class="header-section-mid mt-3 mt-md-0">
                <div class="info-section">
                  <img src="../assets/icons/info-icon.png">
                  <p class="header-section-title mb-2">How to configure rates</p>
                </div>

                <p v-if="!isLoading" class="header-section-info">
                  Configure your rates for {{ regionName }} region
                </p>
              </div>

              <div class="header-section-right">
                <v-dialog v-model="dialog" persistent max-width="430">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="save-changes-btn white--text mb-0" dark v-bind="attrs" v-on="on" block
                      color="black">Save
                      Changes</v-btn>
                  </template>
                  <v-card class="pa-6 outfit-font-family">
                    <v-card-title
                      class="pl-0 pb-1 pt-0 pr-0 letter-spacing-08 font-weight-bold d-flex justify-space-between">
                      Save Changes?
                      <v-icon class="black--text" @click="dialog = false">mdi-close</v-icon>
                    </v-card-title>
                    <div class="d-flex align-center align-start mb-2 pl-0">
                      <img width="15px" src="../assets/icons/info-icon.png">
                      <span size="12" class="pl-1 font-weight-medium info-txt-size letter-spacing-08">Click save button to
                        save
                        your changes.</span>
                    </div>
                    <v-card-actions class="pa-0">
                      <v-spacer></v-spacer>
                      <v-btn class="modal-btns text-capitalize rounded-md font-weight-bold letter-spacing-08" width="48%"
                        dense outlined text @click="dialog = false">
                        Discard Changes
                      </v-btn>

                      <v-btn class="white--text text-capitalize rounded-md font-weight-medium letter-spacing-08" dense
                        width="48%" dark color="black" @click="dialog = false; saveRegion()">
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </div>
            </div>
            <!-- =========================== Sub Header Ends ============================== -->

            <div class="content-section" v-if="!isLoading">
              <template v-for="(truck, i) in dummyTruckOptions">
                <div class="content d-flex flex-column flex-md-row" :key="truck.offering_service_region_id">
                  <div class="left-content-section d-flex flex-row flex-sm-column justify-space-between justify-sm-start">
                    <div class="d-flex flex-column">
                      <p>Truck Type</p>
                      <div class="location-map-icon d-flex align-center mb-1">
                        <v-icon class="text-center icon-color">mdi-truck</v-icon>
                        <p class="region-name" v-if="truck.service_name && !isLoading">{{ truck.service_name }}</p>
                        <p class="region-name" v-if="isLoading">
                          <v-skeleton-loader class="skeleton-mini-width" max-width="100"
                            type="list-item"></v-skeleton-loader>
                        </p>
                      </div>
                    </div>

                    <div class="region-detail d-flex flex-column">
                      <div class="region-option d-flex flex-column">
                        <span>{{ dummyTruckOptions[i].enable === true ? 'Disable?' : 'Enable?' }}</span>
                        <v-switch :label="`Yes`" inset dense v-model="dummyTruckOptions[i].enable" hide-details="true"
                          class="mt-2"
                          @change="enable_disableRegion(truck.region_id, truck.offering_service_id, dummyTruckOptions[i].enable)"></v-switch>
                      </div>
                    </div>
                  </div>

                  <div>
                  <div class="right-content-section">
                    <div class="rate-header">
                      <div class="rate">
                        <div class="tableContainer">
                          <!-- ================= Table first column (no scroll) ================== -->
                          <div>
                            <v-simple-table class="first-table" dense item-key="name">
                              <template v-slot:top>
                                <thead class="table-first-col"
                                  :style="{ backgroundColor: (truck.enable === true ? '#E33029' : '#404040') }">
                                  <tr>
                                    <th class="pl-2 text-left rate-title rounded-l-lg">Rate</th>
                                  </tr>
                                </thead>
                                <tbody class="options">
                                  <tr v-for="(mileage, m) in mileage_list" :key="m" style="height: 39px;">
                                    <td class="mileage-title pl-3">{{ mileage }}</td>
                                  </tr>
                                </tbody>
                              </template>
                            </v-simple-table>
                          </div>


                          <!-- ============ Table second column n onwards (scrolled) =============== -->
                          <div class="second-table">
                            <v-simple-table dense item-key="name" class="d-flex">
                              <template v-slot:top>
                                <template v-for="(rate, r) of truck.service_offering_trucks">
                                  <div :key="r">
                                    <!-- ////////////////////////////// TABLE HEAD \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\ -->
                                    <thead class="table-content-col"
                                      :style="{ backgroundColor: (truck.enable === true ? '#E33029' : '#404040') }">
                                      <tr>
                                        <th class="text-left"
                                          :class="{ 'rounded-r-lg': r === (truck.service_offering_trucks.length - 1) }">
                                          <v-btn class="ma-2 rate-btn"
                                            :class="{ 'selected-ratebtn': (truck.service_offering_trucks[r].enable && dummyTruckOptions[i].enable) }"
                                            outlined :disabled="!dummyTruckOptions[i].enable">

                                            <v-icon v-if="truck.service_offering_trucks[r].enable" class="close-rate-btn"
                                              :style="{ 'color': (truck.service_offering_trucks[r].enable ? 'white' : 'black') }"
                                              @click="enableColumn(truck.offering_service_region_id, r)">mdi-close-circle-outline</v-icon>

                                            <span>{{ rate.name }}</span>

                                            <v-icon v-if="!truck.service_offering_trucks[r].enable" class="add-rate-btn"
                                              @click="enableColumn(truck.offering_service_region_id, r)">mdi-plus-circle</v-icon>

                                            <!-- <v-icon class="option-info-btn"
                                            v-if="dummyTruckOptions[i].enable">mdi-information</v-icon> -->

                                            <v-tooltip max-width="300" bottom>
                                              <template v-slot:activator="{ on }">
                                                <v-icon class="option-info-btn" v-if="dummyTruckOptions[i].enable"
                                                  v-on="on">
                                                  mdi-information
                                                </v-icon>
                                              </template>
                                              <span>{{ rate.additional_specs }}</span>
                                            </v-tooltip>
                                          </v-btn>
                                        </th>
                                      </tr>
                                    </thead>

                                    <!-- ////////////////////////////// TABLE BODY \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\ -->
                                    <tbody class="options">
                                      <tr v-for="(price, p) in rate.truck_pricing" :key="p" style="height: 39px;">
                                        <td>
                                          <div class="ma-2 option-input d-flex align-center"
                                            :class="{ 'enable-option': (dummyTruckOptions[i].enable && rate.truck_pricing[p].enabled) }">

                                            <v-icon v-if="rate.truck_pricing[p].enabled"
                                              @click="selectItem(truck.offering_service_region_id, i, r, p, false)"
                                              :style="{ 'color': (dummyTruckOptions[i].enable && rate.truck_pricing[p].enabled) ? 'white' : 'grey' }"
                                              class="close-rate-btn">mdi-close-circle-outline</v-icon>

                                            <div class="d-flex" style="width: inherit">
                                              <span class="currency">$</span>
                                              <input @blur="updateFormStatus(price, p, r)" type="text" class="ml-0 mileage-btn"
                                                :placeholder="p == 0 ? '300.00' : '5.00'"
                                                v-model="rate.truck_pricing[p].price"
                                                :class="{ 'active-mileage-btn': !dummyTruckOptions[i].enable, 'unactive-mileage-btn': dummyTruckOptions[i].enable }"
                                                :disabled="!dummyTruckOptions[i].enable || !rate.truck_pricing[p].enabled">
                                            </div>

                                            <v-icon v-if="!rate.truck_pricing[p].enabled"
                                              @click="selectItem(truck.offering_service_region_id, i, r, p, true)"
                                              :class="{ 'add-rate-btn_disabled': (!dummyTruckOptions[i].enable || !truck.offering_service_region_id[r].enable), 'add-rate-btn': dummyTruckOptions[i].enable }">mdi-plus-circle</v-icon>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>

                                  </div>
                                </template>
                              </template>
                            </v-simple-table>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="accessories-warning" v-html="accessoriesRequirementsWarning"/>
                  </div>
                </div>
              </template>
            </div>

            <!-- ============================= Skeleton Table Loader =================================== -->

            <div class="skeleton-loader content-section" v-if="isLoading">
              <template v-for="n in 1">
                <div class="content d-flex flex-column flex-md-row" :key="n">
                  <div class="left-content-section d-flex flex-row flex-sm-column justify-space-between justify-sm-start">
                    <div class="d-flex flex-column">
                      <p>Truck Type</p>
                      <div class="location-map-icon d-flex align-center mb-1">
                        <v-icon class="text-center icon-color">mdi-truck</v-icon>
                        <p class="region-name skeleton-full-width">
                          <v-skeleton-loader class="skeleton-mini-width skeleton-remove-padding" max-width="100"
                            type="list-item"></v-skeleton-loader>
                        </p>
                      </div>
                    </div>

                    <div class="region-detail d-flex flex-column">
                      <div class="d-flex flex-column">
                        <v-skeleton-loader class="skeleton-mini-width skeleton-remove-padding" max-width="100"
                          type="list-item"></v-skeleton-loader>
                      </div>
                    </div>
                  </div>

                  <div class="right-content-section">
                    <div class="rate-header">
                      <div class="rate">
                        <div class="tableContainer">
                          <!-- ================= Table first column (no scroll) ================== -->
                          <div>
                            <v-simple-table class="first-table" dense item-key="name">
                              <template v-slot:top>
                                <thead class="table-first-col">
                                  <tr>
                                    <th class="pl-2 text-left rate-title rounded-l-lg">
                                      <v-skeleton-loader class="skeleton-mini-width skeleton-remove-padding"
                                        max-width="180" type="list-item"></v-skeleton-loader>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody class="options">
                                  <tr v-for="(mileage, m) in mileage_list" :key="m" style="height: 39px;">
                                    <td class="mileage-title pl-3 skeleton-full-width">
                                      <v-skeleton-loader class="skeleton-full-width skeleton-remove-padding"
                                        max-width="180" type="list-item"></v-skeleton-loader>
                                    </td>
                                  </tr>
                                </tbody>
                              </template>
                            </v-simple-table>
                          </div>


                          <!-- ============ Table second column n onwards (scrolled) =============== -->
                          <div class="second-table">
                            <v-simple-table dense item-key="name" class="d-flex">
                              <template v-slot:top>
                                <template v-for="r of 5">
                                  <div :key="r">
                                    <thead class="table-content-col">
                                      <tr>
                                        <th class="text-left">
                                          <v-skeleton-loader class="skeleton-mini-width skeleton-remove-padding"
                                            max-width="180" type="list-item"></v-skeleton-loader>
                                        </th>
                                      </tr>
                                    </thead>

                                    <tbody class="options">
                                      <tr v-for="p in 7" :key="p" style="height: 39px;">
                                        <td>
                                          <div class="ma-2 option-input d-flex align-center">
                                            <div class="d-flex skeleton-full-width" style="width: inherit">
                                              <v-skeleton-loader class="skeleton-full-width skeleton-remove-padding"
                                                max-width="480" type="list-item"></v-skeleton-loader>
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </div>
                                </template>
                              </template>
                            </v-simple-table>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>



          </div>
        </div>

        <!-- ############################################################################## -->
        <!-- ############################# MOBILE VIEW ################################### -->
        <!-- ############################################################################## -->

        <div v-if="windowWidth < 600" class="configureRegionRates-styles">
          <div class="header-txt-content align-center mb-7" v-if="selectedLoadType === -1">
            <router-link class="remove-decoration d-flex" to="/region-list">
              <img class="back-icon" src="../assets/icons/black-back-arrow.png">
              <span class="back-btn-txt black--text">Back to region and rates</span>
            </router-link>

          </div>

          <div class="main-content">
            <div class="header-section d-flex" v-if="selectedLoadType === -1">
              <div class="header-section-left">
                <p class="region-title" v-if="!isLoading">{{ localStorage.username }}</p>
                <p class="region-title" v-if="isLoading">
                  <v-skeleton-loader class="skeleton-mini-width skeleton-remove-padding" max-width="120"
                    type="list-item"></v-skeleton-loader>
                </p>

                <div class="location-map-icon d-flex align-start">
                  <v-img src="../assets/icons/location-map-marker.png" alt=""></v-img>
                  <p v-if="!isLoading">{{ regionName }}</p>
                  <div class="skeleton-full-width" v-if="isLoading">
                    <v-skeleton-loader class="skeleton-mini-width skeleton-remove-padding" max-width="120"
                      type="list-item"></v-skeleton-loader>
                  </div>
                </div>

                <p v-if="!isLoading" class="title-info">Configure your rates for {{ regionName }} region</p>
              </div>

              <div class="header-section-mid mt-3 mt-md-0">
                <div class="info-section">
                  <img src="../assets/icons/info-icon.png">
                  <p class="header-section-title mb-0 pl-2">How to configure rates</p>
                </div>

                <p v-if="!isLoading" class="header-section-info">
                  Configure your rates for Permian {{ regionName }}
                </p>
              </div>

              <div class="header-section-right">
                <v-dialog v-model="dialog" persistent max-width="430">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="save-changes-btn mb-0" dense dark v-bind="attrs" v-on="on" block color="black">Save
                      Changes</v-btn>
                  </template>
                  <v-card class="pa-6 outfit-font-family">
                    <v-card-title
                      class="pl-0 pb-1 pt-0 pr-0 letter-spacing-08 font-weight-bold d-flex justify-space-between">
                      Save Changes?
                      <v-icon class="black--text" @click="dialog = false">mdi-close</v-icon>
                    </v-card-title>
                    <div class="d-flex align-center align-start mb-2 pl-0">
                      <img width="15px" src="../assets/icons/info-icon.png">
                      <span size="12" class="pl-1 font-weight-medium info-txt-size letter-spacing-08">Click save button to
                        save
                        your changes.</span>
                    </div>
                    <v-card-actions class="pa-0">
                      <v-spacer></v-spacer>
                      <v-btn class="modal-btns text-capitalize rounded-md font-weight-bold letter-spacing-08" width="48%"
                        dense outlined text @click="dialog = false">
                        Discard Changes
                      </v-btn>

                      <v-btn class="white--text text-capitalize rounded-md font-weight-medium letter-spacing-08" dense
                        width="48%" dark color="black" @click="dialog = false; saveRegion()">
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </div>
            </div>

            <div class="content-section">
              <template v-if="!isLoading">
                <template v-for="(truck, i) in dummyTruckOptions">
                  <div :key="i" v-if="selectedLoadType === -1" class="content d-flex flex-column flex-md-row">

                    <!-- /////////////////// First Row /////////////////////////////////// -->
                    <div
                      class="left-content-section d-flex flex-row flex-sm-column justify-space-between justify-sm-start">
                      <div class="d-flex flex-column">
                        <p>Truck Type</p>
                        <div class="location-map-icon-m d-flex align-center">
                          <v-icon class="text-center icon-color black--text">mdi-truck</v-icon>
                          <p class="region-name">{{ truck.service_name }}</p>
                        </div>
                      </div>

                      <div class="region-detail d-flex flex-column">
                        <div class="region-option d-flex align-center justify-space-between">
                          <span>{{ dummyTruckOptions[i].enable === true ? 'Disable?' : 'Enable?' }}</span>
                          <v-switch inset dense v-model="dummyTruckOptions[i].enable" hide-details="true"
                            class="mt-0"></v-switch>
                        </div>
                      </div>
                    </div>

                    <div class="right-content-section">
                      <!-- /////////////////// Second Row (A) /////////////////////////////////// -->
                      <div class="rate-header mobile-main-selection"
                        :style="{ backgroundColor: (truck.enable === true ? '#E33029' : '#404040') }">

                        <p class="white--text rate-heading mb-0">Rate</p>

                        <template v-for="(rate, r) of truck.service_offering_trucks">
                          <div :key="rate.id" class="d-flex flex-column rate">
                            <v-btn @click.stop="enableLoadType(0, i, r)"
                              class="ma-2 rate-btn d-flex justify-space-between"
                              :class="{ 'selected-ratebtn': (truck.service_offering_trucks[r].enable && dummyTruckOptions[i].enable) }"
                              outlined :disabled="!dummyTruckOptions[i].enable">

                              <div>
                                <v-icon v-if="truck.service_offering_trucks[r].enable" class="close-rate-btn"
                                  :style="{ 'color': (truck.service_offering_trucks[r].enable ? 'white' : 'black') }"
                                  @click.stop="enableColumn(truck.offering_service_region_id, r)">mdi-close-circle-outline</v-icon>

                                <span @click.stop="enableLoadType(0, i, r)">{{ rate.name }}</span>
                              </div>

                              <div>
                                <v-icon v-if="!truck.service_offering_trucks[r].enable" class="add-rate-btn"
                                  @click.stop="enableColumn(truck.offering_service_region_id, r)">mdi-plus-circle</v-icon>
                                <v-icon class="option-info-btn"
                                  v-if="dummyTruckOptions[i].enable">mdi-information</v-icon>
                              </div>
                            </v-btn>
                          </div>
                        </template>
                      </div>
                    </div>
                  </div>
                </template>
              </template>

              <!-- ####################### Skeleton Loader Mobile Screen-A ######################## -->
              <template v-if="isLoading">
                <template v-for="i in 1">
                  <div :key="i" class="content d-flex flex-column flex-md-row">
                    <div
                      class="left-content-section d-flex flex-row flex-sm-column justify-space-between justify-sm-start">
                      <div class="d-flex flex-column skeleton-mini-width">
                        <p>Truck Type</p>
                        <div class="location-map-icon-m d-flex align-center skeleton-full-width">
                          <v-icon class="text-center icon-color black--text">mdi-truck</v-icon>
                          <v-skeleton-loader class="skeleton-mini-width skeleton-remove-padding" max-width="200"
                            type="list-item"></v-skeleton-loader>
                        </div>
                      </div>

                      <div class="region-detail d-flex flex-column skeleton-full-width">
                        <div class="d-flex flex-column skeleton-full-width">
                          <v-skeleton-loader class="skeleton-full-width skeleton-remove-padding" max-width="350"
                          type="list-item"></v-skeleton-loader>
                        </div>
                      </div>
                    </div>

                    <div class="right-content-section">
                      <div class="rate-header mobile-main-selection" style="background-color: #E33029;">

                        <p class="white--text rate-heading mb-0">Rate</p>

                        <template v-for="r of 7">
                          <div :key="r" class="d-flex flex-column rate">
                            <v-btn class="ma-2 selected-ratebtn-grey d-flex justify-space-between" outlined>
                              <div class="skeleton-full-width">
                                  <v-skeleton-loader class="skeleton-full-width skeleton-remove-padding" max-width="220"
                                   type="list-item"></v-skeleton-loader>
                              </div>
                            </v-btn>
                          </div>
                        </template>
                      </div>
                    </div>
                  </div>
                </template>
              </template>




              <div v-if="selectedLoadType === 0">
                <div class="mobile-rate-list-head d-flex justify-space-between align-center rounded-l-lg rounded-r-lg">
                  <p class="rate-heading mb-0 white--text">Rate</p>

                  <v-btn class="mt-2 mb-2 rate-btn-mobile d-flex justify-space-between" ref="rateBtnWidth"
                    :class="{ 'selected-ratebtn': selectedTruckRate.enable, 'unselected-ratebtn': !selectedTruckRate.enable }"
                    outlined color="white">
                    <div>
                      <v-icon v-if="selectedTruckRate.enable" class="unselect-rate-option-icon"
                        @click="enableTruckRate()">mdi-close-circle-outline</v-icon>
                      <p class="d-inline-block mb-0" :class="{ 'ml-3': selectedTruckRate.enable }">{{
                        selectedTruckRate.name }}</p>
                    </div>

                    <div>
                      <v-icon v-if="!selectedTruckRate.enable" class="add-rate-btn"
                        @click="enableTruckRate()">mdi-plus-circle</v-icon>
                      <v-icon class="option-info-btn">mdi-information</v-icon>
                    </div>
                  </v-btn>
                </div>


                <div class="d-flex flex-column selected-load-type-options">
                  <div class="d-flex justify-space-between align-center"
                    v-for="(price, p) in selectedTruckRate.truck_pricing" :key="p">
                    <p class="mb-0">{{ price.mileage_type | mileageFormatting }}</p>

                    <div class="ma-2 m-option-input d-flex justify-space-between align-center"
                      :style="{ width: divWidth + 'px' }"
                      :class="{ 'enable-option': (dummyTruckOptions[selectedTruckIndex].enable && selectedTruckRate.truck_pricing[p].enabled) }">


                      <v-icon v-if="selectedTruckRate.truck_pricing[p].enabled"
                        :style="{ 'color': (selectedTruckRate.truck_pricing[p].enabled) ? 'white' : 'black' }"
                        class="close-rate-btn" @click="selectItem_mobile(p, false)">mdi-close-circle-outline</v-icon>

                      <div class="d-flex" style="width: inherit">
                        <span class="currency">$</span>
                        <input @blur="updateFormStatusForMobile(price, p)" type="text" class="ml-0 mileage-btn"
                          :placeholder="p == 0 ? '300.00' : '5.00'"
                          v-model="selectedTruckRate.truck_pricing[p].price"
                          :class="{ 'active-mileage-btn': !selectedTruckRate.enable, 'unactive-mileage-btn': selectedTruckRate.enable }"
                          :disabled="!selectedTruckRate.truck_pricing[p].enabled">
                      </div>

                      <v-icon v-if="!selectedTruckRate.truck_pricing[p].enabled" class="add-rate-btn"
                        @click="selectItem_mobile(p, true)">mdi-plus-circle</v-icon>
                    </div>
                  </div>
                </div>

                <div class="d-flex justify-space-between mt-3 mobile-footer-btns">
                  <v-btn @click="enableLoadType(-1, -1, -1)"
                    class="text-capitalize rounded-md font-weight-bold letter-spacing-08" width="48%" dense outlined text>
                    Cancel
                  </v-btn>

                  <v-btn class="text-capitalize rounded-md font-weight-medium letter-spacing-08" width="48%" dense dark
                    color="black" @click="saveChanges()"> Save Changes
                  </v-btn>
                </div>
              </div>
            </div>


          </div>
        </div>
      </v-col>
    </v-row>


    <v-dialog v-model="confirmTruckSpec" persistent max-width="440">
      <v-card class="pa-6 accept-order-vcard">
        <v-card-title class="reject-order-main-title pl-0 pb-1 pt-0 pr-0 d-flex justify-space-between">
          Confirm Truck Specification
        </v-card-title>

        <div class="title-info d-flex align-center align-start mb-2 pl-0">
          <span class="pl-1 font-weight-bold accept-order-info">
            By enabling Semi Drop you are confirming the truck specifications</span>
        </div>

        <v-card-actions class="pa-0">
          <v-spacer></v-spacer>
          <v-btn class="accept-order-btns rounded-md" width="50%" dense outlined text @click="confirmTruckSpec = false">
            No
          </v-btn>

          <v-btn class="accept-order-btns white--text rounded-md" dense width="50%" dark color="black"
            @click="confirmTruckSpec = false;">
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-dialog v-model="changesConfirmSpec" persistent max-width="440">
      <v-card class="pa-6 accept-order-vcard">
        <v-card-title class="reject-order-main-title pl-0 pb-1 pt-0 pr-0 d-flex justify-space-between">
          Are you sure?
        </v-card-title>

        <div class="title-info d-flex align-center align-start mb-2 pl-0">
          <span class="pl-1 font-weight-bold accept-order-info">
            Changes made will be lost</span>
        </div>

        <v-card-actions class="pa-0">
          <v-spacer></v-spacer>
          <v-btn class="accept-order-btns rounded-md" width="50%" dense outlined text @click="changesConfirmSpec = false">
            No
          </v-btn>

          <v-btn class="accept-order-btns white--text rounded-md" dense width="50%" dark color="black"
            @click="changesConfirmSpec = false; loadRoute()">
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- <AppLoading :active.sync="isLoading" /> -->
  </div>
</template>

<style scoped lang="scss">
.configureRegionRates-styles {
  @import '../assets/styles/ConfigureRegionRates.scss';

  font-family: 'Outfit', sans-serif;
  background-color: #F4F4F4;
  padding: 25px;
}

</style>

<style lang="scss">
.accessories-warning {
  width: 100%;
  margin-top: 30px;

  ul li {
    font-size: 15px;
  }
}
</style>
    
<script>
import axios from 'axios';
import mixin from "../mixins/auth.mixin.js";

import MobileHeader from '../components/mobile-header.vue';

export default {
  name: 'ConfigureRegionRates',

  components: {
    MobileHeader,
  },
  data: () => ({
    authMixins: mixin.methods,
    dialog: false,
    truckSelectionColor: false,
    confirmTruckSpec: false,
    changesConfirmSpec: false,
    mileage_list: ['Min cost', '0-50 / miles', '51-100 / miles', '101-150 / miles', '151-200 / miles', '201-250 / miles', '251-300 / miles', '301+ miles'],
    dummyTruckOptions: [],
    selectedLoadType: -1,
    windowWidth: 0,
    selectedTruckIndex: -1,
    selectedRateIndex: -1,
    selectedTruckRate: [],
    divWidth: '',
    myTruckPricing: [],
    regionId: null,
    regionName: "",
    serviceData: [],
    isDirty: false,
    isLoading: false,
    forwardRoute: "",
    accessoriesRequirementsWarning: "",
  }),
  computed: {
    localStorage() {
      return localStorage;
    }
  },
  created() {
    this.updateWindowWidth();
    const warningInterval = setInterval(() => {
      if (localStorage.portalConfigs) {
        this.accessoriesRequirementsWarning = JSON.parse(localStorage.portalConfigs).accessoriesRequirementsWarning;
      }
      if (this.accessoriesRequirementsWarning) {
        clearInterval(warningInterval)
      }
    }, 1000);
  },
  filters: {
    mileageFormatting(mileage) {
      if (!mileage) return "Minimum cost";
      let actualMileageFormat = mileage.split(",");
      let reformattedMileage = "";

      if (actualMileageFormat[0] !== '301')
        reformattedMileage = actualMileageFormat[0] + "-" + actualMileageFormat[1] + " / miles";
      else reformattedMileage = "301+ miles";

      return reformattedMileage;
    }
  },
  mounted() {
    document.title = "Carrier Portal - Breaker19.app";

    this.truckSelectionColor = '#404040';

    window.addEventListener("resize", this.updateWindowWidth);

    this.regionId = this.$route.params.regionId;
    this.regionName = this.$route.params.regionName;

    // for (let region of regions) {
    //   if (region.service_offering_trucks.length > 0) {
    //     for (let truck of region.service_offering_trucks) {
    //       if (truck.truck_pricing === null || truck.truck_pricing.length === 0) truck.truck_pricing = JSON.parse(JSON.stringify(this.myTruckPricing));
    //     }
    //   }
    // }

    // this.dummyTruckOptions = regions;
    // console.log(this.dummyTruckOptions);

    window.addEventListener('beforeunload', this.handleBeforeUnload);

    this.loadRegionConfig();
  },

  methods: {
    updateWidth() {
      this.$nextTick(() => {
        const ele = this.$refs.rateBtnWidth;
        if (ele) this.divWidth = (ele.$el.offsetWidth - 5);
      });
    },

    updateWindowWidth() {
      this.windowWidth = window.innerWidth;
    },

    saveRoute(routeUrl) {
      if (this.isDirty) {
        this.changesConfirmSpec = true;
        this.forwardRoute = routeUrl;
      }
      else this.$router.push(routeUrl);
    },

    loadRoute() {
      this.$router.push(this.forwardRoute);
    },

    updateFormStatus(price, p, r) {
      this.isDirty = true;

      const inputValue = price.price.trim();
      const sanitizedValue = inputValue.replace(/[^\d.]/g, '');
      let number = parseFloat(sanitizedValue);

      let formattedNumber = number.toFixed(2);
      this.dummyTruckOptions[0].service_offering_trucks[r].truck_pricing[p].price = formattedNumber;
      if (formattedNumber > 15 && p !== 0) {
        this.dummyTruckOptions[0].service_offering_trucks[r].truck_pricing[p].price = "15.00";
        this.$toast.error('Oops, our max per mile rate we allow at this time is $15 per mile, please try again.', {});
      } else if (formattedNumber < 0.01 || price.price == "") {
        this.dummyTruckOptions[0].service_offering_trucks[r].truck_pricing[p].price = "0.01";
        this.$toast.error('Oops, our min per mile rate we allow at this time is $0.01 per mile, please try again.', {});          
      }
    },
    
    updateFormStatusForMobile(price, p) {
      this.isDirty = true;
      const inputValue = price.price.trim();
      const sanitizedValue = inputValue.replace(/[^\d.]/g, '');
      let number = parseFloat(sanitizedValue);

      let formattedNumber = number.toFixed(2);
      this.selectedTruckRate.truck_pricing[p].price = formattedNumber;
      if (formattedNumber > 15 && p !== 0) {
        this.selectedTruckRate.truck_pricing[p].price = "15.00";
        this.$toast.error('Oops, our max per mile rate we allow at this time is $15 per mile, please try again.', {});
      } else if (formattedNumber < 0.01 || price.price == "") {
        this.selectedTruckRate.truck_pricing[p].price = "0.01";
        this.$toast.error('Oops, our min per mile rate we allow at this time is $0.01 per mile, please try again.', {});
      }

    },

    handleBeforeUnload(event) {
      if (this.isDirty) {
        const warningMessage = "You have unsaved changes! Are you sure you want to leave?";
        event.returnValue = warningMessage;
        return warningMessage;
      }
    },

    enableColumn(truckId, index) {
      for (let truck of this.dummyTruckOptions) {
        if (truck.offering_service_region_id === truckId) {
          truck.service_offering_trucks[index].enable = !truck.service_offering_trucks[index].enable;
          for (let price of truck.service_offering_trucks[index].truck_pricing) {
            if (truck.service_offering_trucks[index].enable) price.enabled = true;
            else price.enabled = false;
          }
        }
      }

      this.isDirty = true;
    },

    enableTruckRate() {
      this.selectedTruckRate.enable = !this.selectedTruckRate.enable;

      for (let price of this.selectedTruckRate.truck_pricing) {
        if (this.selectedTruckRate.enable) price.enabled = true;
        else price.enabled = false;
      }

      this.isDirty = true;
    },

    selectItem(truckId, truckIndex, rateIndex, priceIndex, status) {
      if (!this.dummyTruckOptions[truckIndex].enable) return;

      for (let truck of this.dummyTruckOptions) {
        if (truck.offering_service_region_id === truckId) {
          truck.service_offering_trucks[rateIndex].truck_pricing[priceIndex].enabled = status;
          if (status) truck.service_offering_trucks[rateIndex].enable = true;
          else {
            const allUnselected = truck.service_offering_trucks[rateIndex].truck_pricing.every(price => price.enabled === false);
            if (allUnselected) truck.service_offering_trucks[rateIndex].enable = false;
          }
        }
      }

      this.isDirty = true;
    },

    selectItem_mobile(priceIndex, status) {
      this.selectedTruckRate.truck_pricing[priceIndex].enabled = status;
      if (status) this.selectedTruckRate.enable = true;
      else {
        const allUnselected = this.selectedTruckRate.truck_pricing.every(price => price.enabled === false);
        if (allUnselected) this.selectedTruckRate.enable = false;
      }

      this.isDirty = true;
    },

    enableLoadType(loadTypeNo, truckIndex, rateIndex) {
      this.selectedLoadType = loadTypeNo;

      if (loadTypeNo === 0) {
        this.selectedTruckIndex = truckIndex;
        this.selectedRateIndex = rateIndex;
        this.selectedTruckRate = this.dummyTruckOptions[truckIndex].service_offering_trucks[rateIndex];
        this.updateWidth();
      }
      else {
        this.selectedTruckRate = null;
        this.selectedTruckIndex = null;
        this.selectedRateIndex = null;
      }

      this.isDirty = true;
    },

    saveChanges() {
      this.dummyTruckOptions[this.selectedTruckIndex].service_offering_trucks[this.selectedRateIndex] = this.selectedTruckRate;
      this.selectedLoadType = -1;
    },

    loadRegionConfig() {
      this.isLoading = true;

      let config = {
        headers: {
          "Authorization": localStorage.authToken,
        }
      };

      axios.get(`carrier/region/${this.regionId}/services`, config)
        .then(response => {
          if (response.status === 200) {
            this.serviceData = response.data.data

for (let region of this.serviceData) {
              if (region.service_offering_trucks.length > 0) {
                for (let truck of region.service_offering_trucks) {
                  if (truck.truck_pricing === null) truck.truck_pricing = JSON.parse(JSON.stringify(this.myTruckPricing));
              }
            }
      }

            this.dummyTruckOptions = this.serviceData;
            this.isLoading = false;

          }
          if (response.status === 401) {
            this.authMixins.clearAuthData();
            this.$router.push('/signin');
          }

          this.isLoading = false;
            this.requestStatus = false;
        }).catch(err => {
          if (err.response && err.response.status === 401) {
            this.authMixins.clearAuthData();
            this.$router.push('/signin');
          }
          console.log(err);
          this.isLoading = false;
        });
    },

    saveRegion() {
      let config = {
        headers: {
          "Authorization": localStorage.authToken,
        }
      };

      let regionData = this.dummyTruckOptions;

      axios.post(`carrier/region/add-services`, regionData, config)
        .then(response => {
          if (response.status === 200) {
            this.$toast.success("Region service updated successfully", {});
            this.dummyTruckOptions = response.data.data;
            this.isDirty = false;
          }
          this.requestStatus = false;
        }).catch(err => {
          console.log(err);
          this.$toast.error("Region service update failed", {});
          this.requestStatus = false;
        });
    },

    enable_disableRegion(regionId, serviceId, enableStatus) {
      if (this.requestStatus) return;

      this.requestStatus = true;
      let config = {
        headers: {
          "Authorization": localStorage.authToken,
        }
      };

      let regionData = {
        "enabled": enableStatus
      };

      axios.patch(`carrier/region/enable/${regionId}/${serviceId}`, regionData, config)
        .then(response => {
          if (response.status === 200) console.log("success: ", response);
          this.requestStatus = false;
          if (enableStatus) this.$toast.success("Service enabled", {});
          else this.$toast.success("Service disabled", {});
        }).catch(err => {
          console.log(err);
          this.requestStatus = false;
          if (enableStatus) this.$toast.error("Service enable failed", {});
          else this.$toast.success("Service disable failed", {});
        });
    }
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.updateWindowWidth);
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
  }
}

</script>
    