<template>
  <div class="authorizedUsersList-styles">
    <MobileHeader :isPageLoaded="isLoading"></MobileHeader>

    <div class="main-content">
      <div class="middle-content">
        <div class="d-flex flex-column flex-sm-row justify-space-between">
          <div>
            <p class="user-selected-title mb-0">{{ localStorage.username }}</p>
            <p class="mb-1 auth-user-title">Authorized Users</p>
            <div class="d-flex align-start info-section">
              <v-icon color="#191919" size="15">mdi-alert-circle</v-icon>
              <p class="auth-user-info mb-3 mb-sm-0">
                Authorized users can view and edit your company’s rate and other
                authorized users.
              </p>
            </div>
          </div>
          <div class="align-self-md-center">
            <router-link to="/add-authorized-user">
              <v-btn
                color="#191919"
                large
                class="white--text invite-btn"
                width="100%"
                height="36px"
              >
                + Invite Authorized User
              </v-btn>
            </router-link>
          </div>
        </div>

        <div v-for="(user, index) of usersList" :key="user.auth0_user_id">
          <v-row class="section-card mt-6 py-3 py-sm-1 px-4 no-gutters">
            <v-col md="3" cols="12" class="d-flex align-center">
              <div class="d-flex p-2 align-self-center">
                <v-icon size="40" color="#CECECE" class="account-icon"
                  >mdi-account-circle</v-icon
                >

                <div class="align-self-center pl-1">
                  <span class="user-name">
                    {{ user.first_name }} {{ user.last_name }}
                  </span>
                </div>
              </div>
            </v-col>
            <v-col md="3" cols="6" class="mt-2">
              <div class="d-flex flex-column">
                <div class="personal-information-heading">Phone</div>
                <div class="personal-information">
                  <v-icon color="#191919" size="16">mdi-phone</v-icon>
                  <span v-if="user.mobile_no"
                    >({{ user.country_code }})
                    {{ user.mobile_no | formatToPhone }}</span
                  >
                </div>
              </div>
            </v-col>
            <v-col md="3" cols="6" class="mt-sm-2">
              <div class="d-flex flex-column">
                <div class="personal-information-heading">Email</div>
                <div class="personal-information">
                  <v-icon color="#191919" size="16">mdi-email-outline</v-icon>
                  <span>{{ user.email }}</span>
                </div>
              </div>
            </v-col>
            <br /><br />
            <v-col md="2" cols="6" class="mt-sm-2 mt-2 mt-sm-0">
              <div class="d-flex flex-column">
                <div class="personal-information-heading">Date Authorized</div>
                <div class="personal-information">
                  <v-icon color="#191919" size="16">mdi-calendar-range </v-icon>
                  <span>{{ user.created_at | split_createdAt("date") }}</span>
                </div>
              </div>
            </v-col>
            <v-col
              md="1"
              cols="6"
              class="d-flex align-end align-md-center justify-end"
            >
              <div v-if="loggedUserId && loggedUserId !== user.id">
                <v-icon
                  color="#191919"
                  size="24"
                  @click="selectUser(user.id, index, user.first_name)"
                  @click.stop="confirmationDialog = true"
                  class="delete-icon ml-3"
                  >mdi-trash-can-outline</v-icon
                >
              </div>
            </v-col>
          </v-row>
        </div>

        <template v-if="isLoading">
          <template v-for="n in 3">
            <v-sheet class="pa-3" :key="n">
              <v-row class="section-card mt-6 py-3 py-sm-1 px-4 no-gutters">
                <v-col md="3" cols="12" class="d-flex align-center">
                  <div class="d-flex p-2 align-self-center skeleton-full-width">
                    <v-icon size="40" color="#CECECE" class="account-icon"
                      >mdi-account-circle</v-icon
                    >
                    <v-skeleton-loader
                      class="skeleton-mini-width"
                      max-width="130"
                      type="list-item"
                    ></v-skeleton-loader>
                  </div>
                </v-col>
                <v-col md="3" cols="6" class="mt-2">
                  <div class="d-flex flex-column">
                    <div class="personal-information-heading">Phone</div>
                    <div class="personal-information d-flex">
                      <v-icon color="#191919" size="16">mdi-phone</v-icon>
                      <v-skeleton-loader
                        class="skeleton-mini-width"
                        max-width="130"
                        type="list-item"
                      ></v-skeleton-loader>
                    </div>
                  </div>
                </v-col>
                <v-col md="3" cols="6" class="mt-sm-2">
                  <div class="d-flex flex-column">
                    <div class="personal-information-heading">Email</div>
                    <div class="personal-information d-flex">
                      <v-icon color="#191919" size="16"
                        >mdi-email-outline</v-icon
                      >
                      <v-skeleton-loader
                        class="skeleton-mini-width"
                        max-width="140"
                        type="list-item"
                      ></v-skeleton-loader>
                    </div>
                  </div>
                </v-col>
                <br /><br />
                <v-col md="2" cols="6" class="mt-sm-2 mt-2 mt-sm-0">
                  <div class="d-flex flex-column">
                    <div class="personal-information-heading">
                      Date Authorized
                    </div>
                    <div class="personal-information d-flex">
                      <v-icon color="#191919" size="16"
                        >mdi-calendar-range
                      </v-icon>
                      <v-skeleton-loader
                        class="skeleton-mini-width"
                        max-width="120"
                        type="list-item"
                      ></v-skeleton-loader>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-sheet>
          </template>
        </template>
      </div>
      <h3 class="no-user-msg" v-if="errorStatus">
        {{ errorStatus }}
      </h3>

      <br />
    </div>

    <v-dialog v-model="confirmationDialog" persistent max-width="440">
      <v-card class="pa-6 accept-order-vcard">
        <v-card-title
          class="confirmation-main-title mb-3 pl-0 pb-1 pt-0 pr-0 d-flex justify-space-between"
        >
          Please confirm you wish to delete the user {{ selectedUser.name }}
        </v-card-title>

        <v-card-actions class="pa-0">
          <v-spacer></v-spacer>
          <v-btn
            class="accept-order-btns rounded-md"
            width="50%"
            dense
            outlined
            text
            @click="confirmationDialog = false"
          >
            Cancel
          </v-btn>

          <v-btn
            class="accept-order-btns white--text rounded-md"
            dense
            width="50%"
            dark
            color="black"
            @click="
              confirmationDialog = false;
              deleteUser();
            "
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- <AppLoading :active.sync="isLoading" /> -->
  </div>
</template>

<style scoped lang="scss">
.authorizedUsersList-styles {
  @import "../assets/styles/AuthorizedUserList.scss";

  font-family: "Outfit", sans-serif;

  .inter-font-family {
    font-family: "Inter", sans-serif !important;
  }

  .outfit-font-family {
    font-family: "Outfit", sans-serif !important;
  }
}
</style>

<script>
import axios from "axios";
import mixin from "../mixins/auth.mixin.js";

import MobileHeader from "../components/mobile-header.vue";

export default {
  name: "RegionList",
  components: {
    MobileHeader,
  },
  data: () => ({
    authMixins: mixin.methods,
    usersList: [],
    loggedUserId: null,
    errorStatus: "",
    confirmationDialog: false,
    isLoading: true,
    selectedUser: {
      id: -1,
      index: -1,
      name: "",
    },
  }),
  computed: {
    localStorage() {
      return localStorage;
    },
  },
  created() {},
  mounted() {
    document.title = "Carrier Portal - Breaker19.app";

    this.loggedUserId = localStorage.user_id;
    this.loadUsers();
  },
  filters: {
    split_createdAt: function (value, returnType) {
      if (!value) return "";
      let date_time = value.split("T");
      let date = date_time[0].split("-");
      let time = date_time[1].split(".");

      if (returnType === "date") {
        let mmddyy = date[1] + "/" + date[2] + "/" + date[0];
        return mmddyy;
      } else if (returnType === "time") return time[0];
      else return date_time[0] + " " + date_time[1];
    },

    formatToPhone(value) {
      const input = value.replace(/\D/g, "").substring(0, 10);
      const areaCode = input.substring(0, 3);
      const middle = input.substring(3, 6);
      const last = input.substring(6, 10);

      if (input.length > 6) {
        value = `(${areaCode}) ${middle}-${last}`;
      } else if (input.length > 3) {
        value = `(${areaCode}) ${middle}`;
      } else if (input.length > 0) {
        value = `(${areaCode}`;
      }

      return value;
    },
  },
  methods: {
    selectUser(userId, index, userName) {
      this.selectedUser.id = userId;
      this.selectedUser.index = index;
      this.selectedUser.name = userName;
    },

    loadUsers() {
      let config = {
        headers: {
          Authorization: localStorage.authToken,
        },
      };

      axios
        .get(`/auth/carrier/list-authorized-users`, config)
        .then((response) => {
          if (response.status === 200) {
            this.usersList = [
              ...response.data.data.users.filter((user) => user.id === this.loggedUserId),
              ...response.data.data.users.filter((user) => user.id !== this.loggedUserId),
            ];
            this.errorStatus = "";
          }
          if (response.status === 401) {
            this.authMixins.clearAuthData();
            this.$router.push("/signin");
          }

          this.isLoading = false;
          this.requestStatus = false;
        })
        .catch(async (err) => {
          console.log("err: ", err);
          if (err.response && err.response.status === 401) {
            this.authMixins.clearAuthData();
            this.$router.push("/signin");
          }
          this.requestStatus = false;
          this.isLoading = false;
          this.errorStatus = "No users exist";
        });
    },

    deleteUser() {
      let config = {
        headers: {
          Authorization: localStorage.authToken,
        },
      };

      axios
        .delete(
          `/auth/carrier/authorized-user/${this.selectedUser.id}`,
          config
        )
        .then((response) => {
          if (response.status === 200) {
            this.usersList.splice(this.selectedUser.index, 1);
            this.$toast.info("User deleted successfully", {});
          } else this.$toast.error("User delete failed", {});
          this.requestStatus = false;
        })
        .catch(async (err) => {
          this.$toast.error("User delete failed", {});
          console.log(err);
          this.requestStatus = false;
        });
    },
  },
  beforeDestroy() {},
};
</script>
