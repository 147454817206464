<template>
  <div class="signIn-styles">
    <section class="section-container fill-height auth-module">
      <v-row class="signin">
        <v-col cols="12" md="6" sm="12" class="left">
          <div class="form-section mb-15">
            <div class="logo mb-lg-3">
              <a href="https://www.breaker19.app"><img :src="require('../assets/icons/full_logo.png')" width="100%"></a>
            </div>
            <h3 class="white--text mini-title-size">Carrier Web Portal</h3>
            <h1 class="white--text mb-0 mb-sm-3 heading-title">Grow your trucking business today!</h1>
            <h5 class="white--text title-font-size mb-3">Login</h5>

            <v-form ref="form" class="login-form" @submit.prevent="login()" lazy-validation>

              <div>
                <label class="labels-font-size white--text">E-mail Address</label>
                <v-text-field v-model="email" @input="enterEmail()" required outlined dense height="15"
                  placeholder="e.g. BillyBob@gmail.com" class="rounded-lg labels-font-size vtext-size mt-2 mb-2"
                  type="email" color="#0D1139" background-color="white" hide-details></v-text-field>
                <span class="error-msg">{{ errorEmailMsg }}</span>
              </div>

              <div>
                <label class="labels-font-size white--text">Password</label>
                <v-text-field v-model="password" @input="enterPassword()" height="15"
                  :append-icon="showPass ? 'mdi-eye view-pass' : 'mdi-eye-off view-pass'"
                  @click:append="showPass = !showPass" required outlined dense placeholder="••••••••••••"
                  class="labels-font-size vtext-size rounded-lg" :type="showPass ? 'text' : 'password'" color="#0D1139"
                  background-color="white" hide-details></v-text-field>
                <span class="error-msg">{{ errorPasswordMsg }}</span>
              </div>

              <div class="text-center mb-5 mt-3">
                <v-btn large class="signin-btn rounded-lg labels-font-size text-capitalize white--text" type="submit">
                  Enter
                </v-btn>
              </div>
              <p class="error-msg">{{ responseMsg }}</p>

              <p class="text-center text-sm-left mb-0 text-md-left"><router-link to="/forgot-password"
                  class="font-weight-regular white--text labels-font-size tablet-margin-bottom">Forgot
                  password?</router-link></p>
            </v-form>
          </div>
        </v-col>

        <v-col cols="12" md="6" sm="12" class="right d-flex align-center">
          <div class="right-side-social-buttons">

            <!-- <div class="left-align-txt">
              <h3 class="black--text d-none d-sm-block labels-font-size heading-font-size">You can also login with:</h3>
            </div>
          
            <v-btn color="#fff" large outlined
              class="black--text text-capitalize justify-start font-weight-bold button-size" left><v-icon
                class="google-icon mr-3"></v-icon> Sign in with Google </v-btn>
            <v-btn large outlined color="#fff"
              class="black--text text-capitalize justify-start font-weight-bold button-size"> <v-icon
                class="microsoft-icon mr-3"></v-icon> Sign in with Microsoft</v-btn> -->

            <!-- <div class="left-align-txt">
              <h3 class="black--text heading-font-size no-account-text">Don't have account yet?</h3>
            </div> -->

            <a href="https://carrier-onboarding.breaker19.app">
              <v-btn large outlined color="#fff"
                class="create-account-btn black--text text-capitalize justify-start font-weight-bold button-size"><v-icon
                  class="create-account-icon mr-3"></v-icon> Create new account</v-btn>
            </a>

          </div>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<style scoped lang="scss">
.signIn-styles {
  @import '../assets/styles/SignIn.scss';

  font-family: 'Outfit', sans-serif;
  background-color: #F4F4F4;
}
</style>
  
<script>
import axios from 'axios';
import mixin from "../mixins/auth.mixin.js";

export default {
  data() {
    return {
      showPass: false,
      requestStatus: false,
      password: '',
      errorEmailMsg: '',
      errorPasswordMsg: '',
      email: '',
      authMixins: mixin.methods,
      responseMsg: ''
    }
  },

  mounted() {
    document.title = "Carrier Portal - Breaker19.app";
  },
  methods: {
    enterEmail() {
      if (this.email === '') this.errorEmailMsg = 'Please enter email';
      else this.errorEmailMsg = '';
    },

    enterPassword() {
      if (this.password === '') this.errorPasswordMsg = 'Please enter password';
      else this.errorPasswordMsg = '';
    },

    login() {
      if (this.email === '') this.errorEmailMsg = 'Please enter email';
      else this.errorEmailMsg = '';

      if (this.password === '') this.errorPasswordMsg = 'Please enter password';
      else this.errorPasswordMsg = '';

      if (this.errorEmailMsg !== '' || this.errorPasswordMsg !== '') return;

      if (!this.requestStatus) {
        this.requestStatus = true;

        axios.post(`auth/carrier/signin`, { 'email': this.email, 'password': this.password })
          .then(response => {
            if (response.status === 200) {
              this.authMixins.saveAuthSignin(true, response.data.access_token);
              this.$router.push('/homepage');
            }
            else {
              this.responseMsg = "Invalid Credentials";
              setTimeout(() => {
                this.responseMsg = "";
              }, 3500);
            }
            this.requestStatus = false;
          }).catch(async (err) => {
            console.log(err);
            this.requestStatus = false;
            this.responseMsg = "Invalid Credentials"
            setTimeout(() => {
              this.responseMsg = "";
            }, 3500);
            this.authMixins.saveAuthSignin(false, '');
          });
      }
    }
  }
}
</script>