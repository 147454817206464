import axios from "axios";

export default {
  async consumeInvite({ commit }, payload) {
    axios
      .post("/carriers/invite/consume", {
        invite: payload.invite,
        ...(payload.password && { password: payload.password }),
        ...(payload.password_confirmation && {
          password_confirmation: payload.password_confirmation,
        }),
      })
      .then((response) => {
        if (response.status == 200) {
          if (response.data.success == true) {
            commit("setIsAccountCreated", true);
            commit("setCreateAccountDescription", "Account created successfully");
          }
        }
      })
      .catch(async (err) => {
        commit("setIsAccountCreated", false);
        commit("setCreateAccountDescription", "Account creation failed");
        return err;
      });
  },
};
