<template>
    <div class="mobileHeader-styles">
        <v-app-bar class="header" height="auto" rounded="xl" dense dark>

            <div class="header-contents d-flex align-center justify-space-between">
                <div class="header-left-content d-flex justify-space-between">
                    <div class="logo-section d-flex" v-if="windowWidth > 900 || windowWidth < 600">
                        <img @click="refresh()" style="cursor: pointer;" :src="require('../assets/icons/full_logo.svg')" height="40px">
                    </div>

                    <div class="d-none d-sm-flex justify-space-between header-nav-links">

                        <div class="d-flex align-center flex-column flex-sm-row"
                            :class="{ 'route-active': (selectedRoute == '/homepage' && !isLoading) }">
                            <div class="remove-decoration d-flex flex-column flex-md-row align-center"
                                @click="navigateTo('/homepage')">
                                <v-icon class="text-center icon-setting" color="#525252">mdi-home</v-icon>
                                <span class="ml-0 ml-md-2 white--text">Home</span>
                            </div>
                        </div>

                        <div class="d-flex align-center flex-column flex-sm-row"
                            :class="{ 'route-active': ((selectedRoute == '/shipment-list' || selectedRoute == '/shipment-details') && !isLoading) }">

                            <div class="remove-decoration d-flex flex-column flex-md-row align-center"
                                @click="navigateTo('/shipment-list')">
                                <v-icon class="text-center icon-setting" color="#525252">mdi-truck-cargo-container</v-icon>
                                <span class="ml-0 ml-md-2 white--text">Shipments</span>
                            </div>
                        </div>

                        <div v-if="isHotshotEnabled" class="d-flex align-center flex-column flex-sm-row"
                            :class="{ 'route-active': ((selectedRoute == '/region-list' || selectedRoute == '/configure-regions') && !isLoading) }">

                            <div class="remove-decoration d-flex flex-column flex-md-row align-center"
                                @click="navigateTo('/region-list')">
                                <v-icon class="text-center icon-setting" color="#525252">mdi-currency-usd</v-icon>
                                <span class="ml-0 ml-sm-1 white--text">Hotshot Rates</span>
                            </div>
                        </div>

                        <div v-if="isDiscountsEnabled" class="d-flex align-center flex-column flex-sm-row"
                            :class="{ 'route-active': ((selectedRoute == '/discounts-list') && !isLoading) }">

                            <div class="remove-decoration d-flex flex-column flex-md-row align-center"
                                @click="navigateTo('/discounts-list')">
                                <v-icon class="text-center icon-setting" color="#525252">mdi-brightness-percent</v-icon>
                                <span class="ml-0 ml-sm-1 white--text">Discounts</span>
                            </div>
                        </div>

                        <div class="d-flex align-center  flex-column flex-sm-row"
                            :class="{ 'route-active': ((selectedRoute == '/drivers-list' || selectedRoute == '/add-driver' || selectedRoute == '/edit-driver') && !isLoading) }">

                            <div class="remove-decoration d-flex flex-column flex-md-row align-center"
                                @click="navigateTo('/drivers-list')">
                                <v-icon class="text-center icon-setting" color="#525252">mdi-steering</v-icon>
                                <span class="ml-0 ml-md-2 white--text">Drivers</span>
                            </div>
                        </div>

                        <div class="d-flex align-center flex-column flex-sm-row"
                            :class="{ 'route-active': ((selectedRoute == '/authorized-users-list' || selectedRoute == '/add-authorized-user') && !isLoading) }">

                            <div class="remove-decoration d-flex flex-column flex-md-row align-center"
                                @click="navigateTo('/authorized-users-list')">
                                <v-icon class="text-center icon-setting" color="#525252">mdi-account-multiple</v-icon>
                                <span class="ml-0 ml-md-2 white--text">Users</span>
                            </div>

                        </div>
                    </div>
                </div>


                <div class="d-flex align-center profile-section flex-column flex-sm-row">
                    <v-menu offset-y offset-x content-class="header-menu">
                        <template v-slot:activator="{ on, attrs }">
                            <div class="d-none d-sm-flex flex-column flex-md-row align-center">
                                <v-img v-if="userData" dark v-bind="attrs" v-on="on" :src="localStorage.profileImg"
                                    class="profile-img" alt="" />
                                <span v-bind="attrs" v-on="on" class="ml-0 ml-md-2 profile-name">{{ localStorage.name ? localStorage.name
                                    : '' }}</span>
                            </div>
                            <div class="d-block d-sm-none white black--text rounded-lg">
                                <div v-bind="attrs" v-on="on"
                                    class="d-flex pl-2 pr-2 pt-1 pb-1 align-center justify-space-between">
                                    <span class="font-weight-bold subtitle-2">Menu</span>
                                    <v-icon class="pl-5 menu-icon" color="black">mdi-chevron-down-box</v-icon>
                                </div>
                            </div>
                        </template>

                        <v-list>
                            <template v-if="windowWidth <= 600">
                                <v-list-item @click="navigateTo('/shipment-list')">
                                    <v-icon color="grey">mdi-truck-cargo-container</v-icon>
                                    <v-list-item-title class="pl-4">Shipment</v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="navigateTo('/region-list')">
                                    <v-icon color="grey">mdi-currency-usd</v-icon>
                                    <v-list-item-title class="pl-4">Rates</v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="navigateTo('/drivers-list')">
                                    <v-icon color="grey">mdi-steering</v-icon>
                                    <v-list-item-title class="pl-4">Drivers</v-list-item-title>
                                </v-list-item>
                                <v-list-item v-if="isDiscountsEnabled" @click="navigateTo('/discounts-list')">
                                    <v-icon color="grey">mdi-brightness-percent</v-icon>
                                    <v-list-item-title class="pl-4">Discounts</v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="navigateTo('/authorized-users-list')">
                                    <v-icon color="grey">mdi-account-multiple</v-icon>
                                    <v-list-item-title class="pl-4">Users</v-list-item-title>
                                </v-list-item>
                            </template>

                            <v-list-item @click="navigateTo('/manage-profile')">
                                <v-icon color="grey">mdi-cog-outline</v-icon>
                                <v-list-item-title class="pl-4">Settings</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="logout()">
                                <v-icon color="grey">mdi-logout</v-icon>
                                <v-list-item-title class="pl-4">Logout</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </div>
            </div>
        </v-app-bar>
    </div>
</template>

<style scoped lang="scss">
.mobileHeader-styles {
    @import '../assets/styles/MobileHeader.scss';

    font-family: 'Inter', sans-serif;
    background-color: #F4F4F4;

}
</style>

<script>
import axios from 'axios';
import LogRocket from 'logrocket';
import mixin from "../mixins/auth.mixin.js";

export default {
    name: 'MobileHeader',
    data: () => ({
        windowWidth: 0,
        authMixins: mixin.methods,
        userData: null,
        selectedRoute: "/homepage",
        isFormUpdated: false,
        isLoading: true,
        isHotshotEnabled: false,
        isDiscountsEnabled: false,
        isExecuted: 'false',
        userId: "",
    }),
    props: ['updatedUserImages', 'isFormDirty', 'isPageLoaded'],

    computed: {
        localStorage() {
            return localStorage;
        }
    },
    watch: {
        updatedUserImages: {
            deep: true,
            handler(newVal) {
                if (this.userData) this.userData.profile_url = newVal.profile;
            }
        },

        isPageLoaded: {
            handler(isLoading) {
                this.isLoading = isLoading;
                this.selectedRoute = localStorage.route;
            }
        },

        isFormDirty: {
            handler(newVal) {
                this.isFormUpdated = newVal;
            }
        }
    },

    created() {
        this.updateWindowWidth();
        window.addEventListener('resize', this.updateWindowWidth);
    },
    mounted() {
        let splitted_path = this.$route.path.split('/');
        localStorage.route = ("/" + splitted_path[1]);
        this.selectedRoute = ("/" + splitted_path[1]);

        this.isExecuted = localStorage.loadInfo;
        if (!this.isExecuted || this.isExecuted === 'false') {
            localStorage.loadInfo = 'true';
            this.loadUserData();
        }
        else {
            if (localStorage.udata) {
                this.userData = JSON.parse(localStorage.udata);
            }
        }

        this.loadConfigs();
    },
    methods: {
        refresh() {
            location.href = "/homepage";
        },
        updateWindowWidth() {
            this.windowWidth = window.innerWidth;

            this.userId = localStorage.user_id;
        },

        navigateTo(route) {
            if (this.$route.path !== route) {
                let splitted_path = this.$route.path.split('/');
                localStorage.route = ("/" + splitted_path[1]);
                if (!this.isFormUpdated) this.$router.push(route);
                else this.$emit('route-clicked', route);
            }
        },

        loadUserData() {
            let config = {
                headers: {
                    "Authorization": localStorage.authToken,
                }
            };

            axios.get('auth/carrier/infos', config)
                .then(response => {
                    if (response.status === 200) {
                        this.userData = response.data;
                        
                        let _tempUserdata = {
                            'company_logo_url': this.userData.carrier.company_logo_url,
                            'profile_url': this.userData.profile_url,
                            'name': `${this.userData.first_name} ${this.userData.last_name}`,
                        }
                        localStorage.udata = JSON.stringify(this.userData);
                        localStorage.user_id = this.userData.id;
                        localStorage.timezone = response.data.time_zone;
                        localStorage.name = response.data.first_name + ' ' + response.data.last_name;
                        localStorage.profileImg = response.data.profile_url; 
                        if (this.userData && this.userId !== this.userData.id) {
                            console.log("Log rocket identify");
                            this.userId = this.userData.id;

                            LogRocket.identify(this.userData.id, {
                                name: _tempUserdata.name,
                                email: this.userData.email,
                            });
                        }
                    }
                }).catch(async (err) => {
                    console.log(err);
                });
        },

        loadConfigs() {
            let config = {
                headers: {
                    "Authorization": localStorage.authToken,
                }
            };

            axios.get('carriers/portal-configs', config)
                .then(response => {
                    if (response.status === 200) {
                        const configs = {};
                        response.data.forEach(({key, value}) => configs[key] = value);
                        localStorage.portalConfigs = JSON.stringify(configs);
                    }
                }).catch(async (err) => {
                    console.log(err);
                });

            axios.get('carriers/services-status', config)
                .then(response => {
                  const serviceStatus = response.data.find((serviceStatus) => !serviceStatus.service.isFTL);

                  this.isHotshotEnabled = (!serviceStatus || !serviceStatus.disabled);
                }).catch(async (err) => {
                    console.log(err);
                });

            axios.get('carriers/discounts', config)
                .then(response => {
                    this.isDiscountsEnabled = response.data.length > 0;
                }).catch(async (err) => {
                    console.log(err);
                });
        },
        logout() {
            this.authMixins.clearAuthData();
            this.$router.push("/signin");
        }
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.updateWindowWidth);
    }
}

</script>