<template>
  <div>
    <MobileHeader
      :isPageLoaded="isLoading"
      :updatedUserImages="userImages"
      @route-clicked="saveRoute"
      :isFormDirty="isDirty"
    ></MobileHeader>

    <v-row style="background-color: #f1f1f1">
      <v-col style="background-color: #f1f1f1">
        <div class="manageProfile-styles">
          <div class="main-content">
            <div class="middle-content">
              <div class="header-txt-content align-center mb-7">
                <div
                  class="d-flex align-center"
                  @click="saveRoute('/homepage')"
                >
                  <!-- <router-link to="/homepage" class="remove-decoration d-flex align-center"> -->
                  <img
                    class="back-icon"
                    src="../assets/icons/black-back-arrow.png"
                  />
                  <span class="back-btn-txt"> Back to home </span>
                  <!-- </router-link> -->
                </div>
              </div>

              <!-- ======================= MAIN SELECTION SCREEN ======================== -->
              <div class="d-flex align-center">
                <v-row class="justify-sm-center grey-bg">
                  <!-- ################### DESKTOP VIEW (600>) ###################### -->
                  <v-col
                    cols="3"
                    class="col-5 col-sm-3 col-md-3 manage-profile-options-desktop"
                  >
                    <p class="manage-profile-heading">Settings</p>

                    <div class="d-flex align-center">
                      <div class="profile-icon" v-if="!isLoading">
                        <v-img :src="userImages.profile"></v-img>
                        <!-- <label for="profile-img">
                             <v-icon class="icon-setting" color="#525252">mdi-pencil-circle</v-icon>
                        </label> -->
                      </div>

                      <div class="profile-icon" v-if="isLoading">
                        <v-skeleton-loader
                          class="skeleton-full-width"
                          type="avatar"
                        ></v-skeleton-loader>
                      </div>

                      <div
                        class="d-flex flex-column align-start ml-1"
                        v-if="!isLoading && userData.name"
                      >
                        <p class="profile-name mb-0">{{ userData.name }}</p>
                        <p class="profile-email mb-0">{{ userData.email }}</p>
                      </div>

                      <div
                        class="d-flex flex-column align-start ml-1 skeleton-full-width"
                        v-if="isLoading && !userData.name"
                      >
                        <p class="profile-name mb-0 skeleton-mini-width">
                          <v-skeleton-loader
                            class="skeleton-remove-padding skeleton-full-width"
                            type="list-item"
                          ></v-skeleton-loader>
                        </p>
                        <p class="profile-email mb-0 skeleton-full-width">
                          <v-skeleton-loader
                            class="skeleton-remove-padding skeleton-full-width"
                            type="list-item"
                          ></v-skeleton-loader>
                        </p>
                      </div>
                    </div>

                    <div class="options d-flex flex-column mt-6">
                      <div
                        class="d-flex align-center option"
                        @mouseover="mouseOver(0)"
                        @mouseleave="mouseLeave(0)"
                        @click="profileOption(0)"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="25"
                          viewBox="0 0 24 25"
                          fill="none"
                        >
                          <path
                            d="M12 21.2065C9 21.2065 6.348 19.6587 4.8 17.3369C4.836 14.9185 9.6 13.5883 12 13.5883C14.4 13.5883 19.164 14.9185 19.2 17.3369C18.4067 18.5273 17.335 19.5029 16.0795 20.1776C14.8239 20.8524 13.423 21.2057 12 21.2065ZM12 4.03541C12.9548 4.03541 13.8705 4.41761 14.5456 5.09793C15.2207 5.77826 15.6 6.70098 15.6 7.6631C15.6 8.62522 15.2207 9.54794 14.5456 10.2283C13.8705 10.9086 12.9548 11.2908 12 11.2908C11.0452 11.2908 10.1295 10.9086 9.45442 10.2283C8.77928 9.54794 8.4 8.62522 8.4 7.6631C8.4 6.70098 8.77928 5.77826 9.45442 5.09793C10.1295 4.41761 11.0452 4.03541 12 4.03541ZM12 0.407715C10.4241 0.407715 8.86371 0.720492 7.4078 1.32819C5.95189 1.93588 4.62902 2.8266 3.51472 3.94947C1.26428 6.21722 0 9.29294 0 12.5C0 15.7071 1.26428 18.7828 3.51472 21.0506C4.62902 22.1735 5.95189 23.0642 7.4078 23.6719C8.86371 24.2796 10.4241 24.5923 12 24.5923C15.1826 24.5923 18.2348 23.3183 20.4853 21.0506C22.7357 18.7828 24 15.7071 24 12.5C24 5.81298 18.6 0.407715 12 0.407715Z"
                            :fill="svgProfileFill"
                          />
                        </svg>
                        <p class="mb-0 ml-2">Profile</p>
                      </div>

                      <div
                        class="d-flex align-center option"
                        @mouseover="mouseOver(1)"
                        @click="passwordOption(0)"
                      >
                        <svg
                          class="change-password-icon"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="18"
                          viewBox="0 0 24 25"
                          fill="none"
                        >
                          <g id="grommet-icons:shield-security">
                            <path
                              id="Vector"
                              d="M12 14.7C12.394 14.7 12.7841 14.6224 13.1481 14.4716C13.512 14.3208 13.8427 14.0998 14.1213 13.8213C14.3999 13.5427 14.6209 13.212 14.7716 12.848C14.9224 12.484 15 12.0939 15 11.7M12 14.7C11.2044 14.7 10.4413 14.3839 9.87868 13.8213C9.31607 13.2587 9 12.4956 9 11.7M12 14.7V17.7M15 11.7C15 11.306 14.9224 10.9159 14.7716 10.5519C14.6209 10.1879 14.3999 9.85721 14.1213 9.57863C13.8427 9.30005 13.512 9.07908 13.1481 8.92831C12.7841 8.77755 12.394 8.69995 12 8.69995M15 11.7H18M12 8.69995C11.2044 8.69995 10.4413 9.01602 9.87868 9.57863C9.31607 10.1412 9 10.9043 9 11.7M12 8.69995V5.69995M9 11.7H6M8 7.69995L10 9.69995M14 13.7L16 15.7M16 7.69995L14 9.69995M10 13.7L8 15.7M12 22.7C12 22.7 3 18.7 3 11.7V5.69995L12 2.69995L21 5.69995V11.7C21 18.7 12 22.7 12 22.7Z"
                              :fill="svgPwdFill"
                              stroke="black"
                              stroke-width="1.5"
                            />
                          </g>
                        </svg>
                        <p class="mb-0 ml-2">Password</p>
                      </div>

                      <div
                        class="d-flex align-center option"
                        @mouseover="mouseOver(2)"
                        @mouseleave="mouseLeave(2)"
                        @click="LogoOption(0)"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="25"
                          viewBox="0 0 24 25"
                          fill="none"
                        >
                          <path
                            d="M18 15.5H16V17.5H18M18 11.5H16V13.5H18M20 19.5H12V17.5H14V15.5H12V13.5H14V11.5H12V9.5H20M10 7.5H8V5.5H10M10 11.5H8V9.5H10M10 15.5H8V13.5H10M10 19.5H8V17.5H10M6 7.5H4V5.5H6M6 11.5H4V9.5H6M6 15.5H4V13.5H6M6 19.5H4V17.5H6M12 7.5V3.5H2V21.5H22V7.5H12Z"
                            :fill="svgLogoFill"
                          />
                        </svg>
                        <p class="mb-0 ml-2">Company Logo</p>
                      </div>

                      <!-- <div class="d-flex align-center option" @mouseover="mouseOver(3)"
                          @mouseleave="mouseLeave(3)" @click="updateTimeOption(0)">
                          <v-icon class="text-center clock-icon" :style="{ 'color': clockColor }"
                              color="#525252">mdi-clock-time-three-outline</v-icon>
                          <p class="mb-0 ml-2">Update time</p>
                      </div> -->
                    </div>
                  </v-col>

                  <!-- ################### MOBILE VIEW (600<) ###################### -->
                  <v-col
                    cols="1"
                    :style="{ backgroundColor: mobilebgcolor }"
                    class="d-flex d-sm-none flex-column align-center mobile-nav-bar"
                  >
                    <div v-if="menuExpended === false">
                      <div
                        @click="expandMenu()"
                        class="mb-5 d-flex justify-center mobile-option"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M3 6H13V8H3V6ZM3 16H13V18H3V16ZM3 11H15V13H3V11ZM16 7L14.58 8.39L18.14 12L14.58 15.61L16 17L21 12L16 7Z"
                            fill="black"
                          />
                        </svg>
                      </div>

                      <div class="mb-5 mobile-profile-pic">
                        <v-img :src="userImages.profile"></v-img>
                      </div>

                      <div
                        @click="profileOption(0), mobileNavSelection(0)"
                        :class="{ 'mobile-option-selection': mOption === 0 }"
                        class="mb-5 d-flex justify-center"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="25"
                          viewBox="0 0 24 25"
                          fill="none"
                        >
                          <path
                            d="M12 21.2065C9 21.2065 6.348 19.6587 4.8 17.3369C4.836 14.9185 9.6 13.5883 12 13.5883C14.4 13.5883 19.164 14.9185 19.2 17.3369C18.4067 18.5273 17.335 19.5029 16.0795 20.1776C14.8239 20.8524 13.423 21.2057 12 21.2065ZM12 4.03541C12.9548 4.03541 13.8705 4.41761 14.5456 5.09793C15.2207 5.77826 15.6 6.70098 15.6 7.6631C15.6 8.62522 15.2207 9.54794 14.5456 10.2283C13.8705 10.9086 12.9548 11.2908 12 11.2908C11.0452 11.2908 10.1295 10.9086 9.45442 10.2283C8.77928 9.54794 8.4 8.62522 8.4 7.6631C8.4 6.70098 8.77928 5.77826 9.45442 5.09793C10.1295 4.41761 11.0452 4.03541 12 4.03541ZM12 0.407715C10.4241 0.407715 8.86371 0.720492 7.4078 1.32819C5.95189 1.93588 4.62902 2.8266 3.51472 3.94947C1.26428 6.21722 0 9.29294 0 12.5C0 15.7071 1.26428 18.7828 3.51472 21.0506C4.62902 22.1735 5.95189 23.0642 7.4078 23.6719C8.86371 24.2796 10.4241 24.5923 12 24.5923C15.1826 24.5923 18.2348 23.3183 20.4853 21.0506C22.7357 18.7828 24 15.7071 24 12.5C24 5.81298 18.6 0.407715 12 0.407715Z"
                            :fill="mOption === 0 ? 'white' : 'black'"
                          />
                        </svg>
                      </div>

                      <div
                        @click="passwordOption(0), mobileNavSelection(1)"
                        :class="{ 'mobile-option-selection': mOption === 1 }"
                        class="mb-5 d-flex justify-center"
                      >
                        <svg
                          class="change-password-icon"
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 24 25"
                          fill="none"
                        >
                          <g id="grommet-icons:shield-security">
                            <path
                              id="Vector"
                              d="M12 14.7C12.394 14.7 12.7841 14.6224 13.1481 14.4716C13.512 14.3208 13.8427 14.0998 14.1213 13.8213C14.3999 13.5427 14.6209 13.212 14.7716 12.848C14.9224 12.484 15 12.0939 15 11.7M12 14.7C11.2044 14.7 10.4413 14.3839 9.87868 13.8213C9.31607 13.2587 9 12.4956 9 11.7M12 14.7V17.7M15 11.7C15 11.306 14.9224 10.9159 14.7716 10.5519C14.6209 10.1879 14.3999 9.85721 14.1213 9.57863C13.8427 9.30005 13.512 9.07908 13.1481 8.92831C12.7841 8.77755 12.394 8.69995 12 8.69995M15 11.7H18M12 8.69995C11.2044 8.69995 10.4413 9.01602 9.87868 9.57863C9.31607 10.1412 9 10.9043 9 11.7M12 8.69995V5.69995M9 11.7H6M8 7.69995L10 9.69995M14 13.7L16 15.7M16 7.69995L14 9.69995M10 13.7L8 15.7M12 22.7C12 22.7 3 18.7 3 11.7V5.69995L12 2.69995L21 5.69995V11.7C21 18.7 12 22.7 12 22.7Z"
                              fill="white"
                              stroke="black"
                              stroke-width="1.5"
                            />
                          </g>
                        </svg>
                      </div>

                      <div
                        @click="LogoOption(0), mobileNavSelection(2)"
                        :class="{ 'mobile-option-selection': mOption === 2 }"
                        class="mb-5 d-flex justify-center"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="25"
                          viewBox="0 0 24 25"
                          fill="none"
                        >
                          <path
                            d="M18 15.5H16V17.5H18M18 11.5H16V13.5H18M20 19.5H12V17.5H14V15.5H12V13.5H14V11.5H12V9.5H20M10 7.5H8V5.5H10M10 11.5H8V9.5H10M10 15.5H8V13.5H10M10 19.5H8V17.5H10M6 7.5H4V5.5H6M6 11.5H4V9.5H6M6 15.5H4V13.5H6M6 19.5H4V17.5H6M12 7.5V3.5H2V21.5H22V7.5H12Z"
                            :fill="mOption === 2 ? 'white' : 'black'"
                          />
                        </svg>
                      </div>

                      <div
                        class="d-flex justify-center"
                        @click="updateTimeOption(0), mobileNavSelection(3)"
                        :class="{ 'mobile-option-selection': mOption === 3 }"
                      >
                        <v-icon
                          class="text-center clock-icon"
                          :style="{ color: clockColor }"
                          color="#525252"
                          >mdi-clock-time-three-outline
                        </v-icon>
                      </div>
                    </div>

                    <!-- ------------------------ Expanded manu -------------------------- -->
                    <div
                      class="manage-profile-options-mobile"
                      v-if="menuExpended === true"
                    >
                      <div @click="expandMenu()" class="mb-5">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="12"
                          viewBox="0 0 18 12"
                          fill="none"
                        >
                          <path
                            d="M18 12L8 12V10L18 10V12ZM18 2L8 2V0L18 0V2ZM18 7L6 7V5L18 5V7ZM5 11L6.42 9.61L2.86 6L6.42 2.39L5 1L0 6L5 11Z"
                            fill="black"
                          />
                        </svg>
                      </div>

                      <div class="d-flex align-center">
                        <div class="profile-icon">
                          <v-img :src="userImages.profile"></v-img>
                        </div>

                        <div class="d-flex flex-column align-start ml-1">
                          <p class="profile-name mb-0">{{ userData.name }}</p>
                          <p class="profile-email mb-0">{{ userData.email }}</p>
                        </div>
                      </div>

                      <div class="options d-flex flex-column mt-6">
                        <div
                          class="d-flex align-center option"
                          @mouseover="mouseOver(0)"
                          @mouseleave="mouseLeave(0)"
                          @click="profileOption(0)"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="25"
                            viewBox="0 0 24 25"
                            fill="none"
                          >
                            <path
                              d="M12 21.2065C9 21.2065 6.348 19.6587 4.8 17.3369C4.836 14.9185 9.6 13.5883 12 13.5883C14.4 13.5883 19.164 14.9185 19.2 17.3369C18.4067 18.5273 17.335 19.5029 16.0795 20.1776C14.8239 20.8524 13.423 21.2057 12 21.2065ZM12 4.03541C12.9548 4.03541 13.8705 4.41761 14.5456 5.09793C15.2207 5.77826 15.6 6.70098 15.6 7.6631C15.6 8.62522 15.2207 9.54794 14.5456 10.2283C13.8705 10.9086 12.9548 11.2908 12 11.2908C11.0452 11.2908 10.1295 10.9086 9.45442 10.2283C8.77928 9.54794 8.4 8.62522 8.4 7.6631C8.4 6.70098 8.77928 5.77826 9.45442 5.09793C10.1295 4.41761 11.0452 4.03541 12 4.03541ZM12 0.407715C10.4241 0.407715 8.86371 0.720492 7.4078 1.32819C5.95189 1.93588 4.62902 2.8266 3.51472 3.94947C1.26428 6.21722 0 9.29294 0 12.5C0 15.7071 1.26428 18.7828 3.51472 21.0506C4.62902 22.1735 5.95189 23.0642 7.4078 23.6719C8.86371 24.2796 10.4241 24.5923 12 24.5923C15.1826 24.5923 18.2348 23.3183 20.4853 21.0506C22.7357 18.7828 24 15.7071 24 12.5C24 5.81298 18.6 0.407715 12 0.407715Z"
                              :fill="svgProfileFill"
                            />
                          </svg>
                          <p class="mb-0 ml-2">Profile Picture</p>
                        </div>

                        <div
                          class="d-flex align-center option"
                          @mouseover="mouseOver(1)"
                          @click="passwordOption(0)"
                        >
                          <svg
                            class="change-password-icon"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="18"
                            viewBox="0 0 24 25"
                            fill="none"
                          >
                            <g id="grommet-icons:shield-security">
                              <path
                                id="Vector"
                                d="M12 14.7C12.394 14.7 12.7841 14.6224 13.1481 14.4716C13.512 14.3208 13.8427 14.0998 14.1213 13.8213C14.3999 13.5427 14.6209 13.212 14.7716 12.848C14.9224 12.484 15 12.0939 15 11.7M12 14.7C11.2044 14.7 10.4413 14.3839 9.87868 13.8213C9.31607 13.2587 9 12.4956 9 11.7M12 14.7V17.7M15 11.7C15 11.306 14.9224 10.9159 14.7716 10.5519C14.6209 10.1879 14.3999 9.85721 14.1213 9.57863C13.8427 9.30005 13.512 9.07908 13.1481 8.92831C12.7841 8.77755 12.394 8.69995 12 8.69995M15 11.7H18M12 8.69995C11.2044 8.69995 10.4413 9.01602 9.87868 9.57863C9.31607 10.1412 9 10.9043 9 11.7M12 8.69995V5.69995M9 11.7H6M8 7.69995L10 9.69995M14 13.7L16 15.7M16 7.69995L14 9.69995M10 13.7L8 15.7M12 22.7C12 22.7 3 18.7 3 11.7V5.69995L12 2.69995L21 5.69995V11.7C21 18.7 12 22.7 12 22.7Z"
                                :fill="svgPwdFill"
                                stroke="black"
                                stroke-width="1.5"
                              />
                            </g>
                          </svg>
                          <p class="mb-0 ml-2">Password</p>
                        </div>

                        <div
                          class="d-flex align-center option"
                          @mouseover="mouseOver(2)"
                          @mouseleave="mouseLeave(2)"
                          @click="LogoOption(0)"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="25"
                            viewBox="0 0 24 25"
                            fill="none"
                          >
                            <path
                              d="M18 15.5H16V17.5H18M18 11.5H16V13.5H18M20 19.5H12V17.5H14V15.5H12V13.5H14V11.5H12V9.5H20M10 7.5H8V5.5H10M10 11.5H8V9.5H10M10 15.5H8V13.5H10M10 19.5H8V17.5H10M6 7.5H4V5.5H6M6 11.5H4V9.5H6M6 15.5H4V13.5H6M6 19.5H4V17.5H6M12 7.5V3.5H2V21.5H22V7.5H12Z"
                              :fill="svgLogoFill"
                            />
                          </svg>
                          <p class="mb-0 ml-2">Company Logo</p>
                        </div>

                        <div
                          class="d-flex align-center option"
                          @mouseover="mouseOver(3)"
                          @mouseleave="mouseLeave(3)"
                          @click="updateTimeOption(0)"
                        >
                          <v-icon
                            class="text-center clock-icon"
                            :style="{ color: clockColor }"
                            color="#525252"
                            >mdi-clock-time-three-outline
                          </v-icon>
                          <p class="mb-0 ml-2">Update time</p>
                        </div>
                      </div>
                    </div>
                  </v-col>

                  <!-- ################### RIGHT SECTION (BOTH) ###################### -->
                  <v-col
                    cols="9"
                    class="col-5 col-xs-11 col-sm-8 col-md-8 profile-selection-option"
                    :style="{ backgroundColor: mobilebgcolor }"
                  >
                    <div class="right-section-main">
                      <div
                        v-if="selectedProfileOption === 0"
                        class="profile-pic-heading"
                      >
                        <p class="mb-0">Update Profile Picture</p>
                      </div>
                      <div
                        v-if="selectedLogoOption === 0"
                        class="profile-pic-heading"
                      >
                        <p class="mb-0">Company Logo</p>
                      </div>

                      <div
                        class="profile-edit-section d-flex flex-column align-center mt-10"
                      >
                        <div
                          class="profile-pic"
                          v-if="!isLoading && selectedProfileOption === 0"
                        >
                          <v-img :src="userImages.profile"></v-img>
                        </div>
                        <div
                          class="company-logo"
                          v-if="!isLoading && selectedLogoOption === 0"
                        >
                          <v-img :src="userImages.logo"></v-img>
                        </div>

                        <div
                          class="profile-icon"
                          v-if="isLoading && selectedProfileOption === 0"
                        >
                          <v-skeleton-loader
                            class="skeleton-full-width skeleton-avatar-setting"
                            type="avatar"
                          ></v-skeleton-loader>
                        </div>

                        <div
                          class="company-logo mb-4"
                          v-if="isLoading && selectedLogoOption === 0"
                        >
                          <v-skeleton-loader
                            class="skeleton-full-width skeleton-avatar-setting"
                            max-height="100"
                            type="image"
                          ></v-skeleton-loader>
                        </div>

                        <div
                          v-if="selectedLogoOption === 0"
                          class="title-info d-flex align-center align-start mb-5 mt-3"
                        >
                          <img
                            class="title-info"
                            src="../assets/icons/info-icon.png"
                          />
                          <p class="title-info mb-0 ml-1">
                            Company Logo will be visible to Breaker19 buyers
                          </p>
                        </div>

                        <v-btn
                          v-if="selectedProfileOption === 0"
                          type="submit"
                          class="change-profile-btn mt-4 white--text"
                          height="39px"
                          block
                          color="black"
                          @click="profileOption(1)"
                        >
                          Change Profile Picture

                          <v-icon class="ml-5" color="white"
                            >mdi-pencil-outline</v-icon
                          >
                        </v-btn>

                        <!-- <br v-if="selectedLogoOption === 0"> -->
                        <div class="change-company-logo-div">
                          <v-btn
                            v-if="selectedLogoOption === 0"
                            type="submit"
                            class="change-profile-btn white--text mb-2"
                            height="39px"
                            block
                            color="black"
                            @click="LogoOption(1)"
                          >
                            Change Company Logo

                            <v-icon class="ml-5" color="white"
                              >mdi-pencil-outline</v-icon
                            >
                          </v-btn>
                        </div>
                      </div>

                      <!-- ///////////////////////////////////////////////////////////////////// -->
                      <div class="">
                        <div
                          class="d-flex flex-column flex-sm-row align-start align-sm-center justify-space-between"
                        >
                          <div
                            class="profile-pic-heading"
                            v-if="selectedProfileOption === 1"
                          >
                            <p class="mb-0">Update Profile Picture</p>
                            <h4 v-if="requestStatus">Uploading ...</h4>
                          </div>

                          <div
                            class="profile-pic-heading"
                            v-if="selectedLogoOption === 1"
                          >
                            <p class="mb-0">Change Company Logo</p>
                            <h4 v-if="requestStatus">Uploading ...</h4>
                          </div>

                          <div
                            v-if="
                              selectedProfileOption === 1 ||
                              selectedLogoOption === 1
                            "
                            class="d-flex flex-column flex-sm-row justify-space-between"
                          >
                            <label
                              for="profile-img1"
                              class="choose-img-btn pa-1 d-flex align-center justify-center"
                            >
                              <span>Choose other image</span>
                              <input
                                id="profile-img1"
                                ref="fileInput"
                                class="d-none"
                                @change="checkImg($event, 1)"
                                type="file"
                                accept="image/*"
                              />
                            </label>
                          </div>
                        </div>

                        <div class="mt-12" v-if="selectedProfileOption === 0">
                          <div class="profile-pic-heading">
                            <p class="mb-2">Update Timezone</p>
                          </div>

                          <div
                            class="timezone mt-7 d-flex flex-column align-center"
                          >
                            <template v-if="!isLoading">
                              <v-autocomplete
                                v-model="userTimezone"
                                @input="updateChangeStatus"
                                :items="timezone"
                                width="100%"
                                :search-input.sync="searchTimezone"
                                item-text="label"
                                item-value="tzCode"
                                flat
                                auto-select-first
                                outlined
                                dense
                                :rules="timeZoneRule"
                              >
                              </v-autocomplete>
                            </template>

                            <div
                              class="skeleton-mini-width mb-4"
                              v-if="isLoading"
                            >
                              <v-skeleton-loader
                                class="skeleton-full-width skeleton-remove-padding"
                                type="list-item"
                              ></v-skeleton-loader>
                            </div>

                            <v-btn
                              @click="updateTime()"
                              class="save-btn ml-3 remove-letter-spacing white--text text-capitalize rounded-md font-weight-medium"
                              dense
                              dark
                              color="black"
                            >
                              Update
                            </v-btn>
                          </div>
                        </div>

                        <div class="mt-12" v-if="selectedProfileOption === 0">
                          <div class="profile-pic-heading">
                            <p class="mb-2">Update Mobile Number</p>
                          </div>
                          <div
                            class="mb-5 mt-7 d-flex flex-column justify-center"
                          >
                            <div class="d-flex justify-center">
                              <div class="dial-code-input" v-if="!isLoading">
                                <v-select
                                  class="input-field-setting dial-select"
                                  v-model="selectedCode"
                                  hide-details="true"
                                  :items="dialCodes"
                                  label=""
                                  solo
                                  dense
                                  flat
                                  background-color="#F4F4F4"
                                >
                                  <template v-slot:append>
                                    <img
                                      src="@/assets/icons/dropdown-icon.png"
                                      class="dropdown-icon"
                                    />
                                  </template>
                                </v-select>
                              </div>

                              <div class="phone-field ml-2" v-if="!isLoading">
                                <v-text-field
                                  @input="enterPhone($event)"
                                  @keydown="checkNumber($event)"
                                  type="text"
                                  v-model="mobileNo"
                                  class="input-field-setting"
                                  placeholder="(432) 400-4121"
                                  solo
                                  dense
                                  background-color="#F4F4F4"
                                  flat
                                  hide-details="true"
                                ></v-text-field>

                                <p class="error-msg mb-3">
                                  {{ errorPhoneMsg }}
                                </p>
                              </div>

                              <div
                                class="d-flex skeleton-mini-width mb-4"
                                v-if="!mobileNo && isLoading"
                              >
                                <v-skeleton-loader
                                  class="skeleton-full-width skeleton-remove-padding"
                                  max-width="150"
                                  type="list-item"
                                ></v-skeleton-loader>
                                <v-skeleton-loader
                                  class="skeleton-full-width skeleton-remove-padding"
                                  max-width="400"
                                  type="list-item"
                                ></v-skeleton-loader>
                              </div>
                            </div>

                            <div class="text-center">
                              <v-btn
                                @click="updatePhone()"
                                class="save-btn ml-3 remove-letter-spacing white--text text-capitalize rounded-md font-weight-medium"
                                dense
                                dark
                                color="black"
                              >
                                Update
                              </v-btn>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- ======================= CHANGE PROFILE PART 1 ======================== -->
                      <template
                        v-if="
                          selectedProfileOption === 1 ||
                          selectedLogoOption === 1
                        "
                      >
                        <!-- <div class="crop-section-start"> -->
                        <div
                          class="mt-5"
                          :class="{
                            'logo-crop-img-section': selectedLogoOption === 1,
                          }"
                        >
                          <vue-croppie
                            ref="cropper"
                            :enforceBoundary="false"
                            :enableResize="false"
                            :showZoomer="true"
                            :enableOrientation="true"
                            :boundary="cropperBoundries"
                            :viewport="cropperViewport"
                            @update="update"
                          >
                          </vue-croppie>
                        </div>

                        <!-- <div clas="mt-3" v-if="!imgLoaded">
                            <h3 class="loading-img">Loading Image...</h3>
                        </div> -->
                        <br v-if="selectedLogoOption === 1" />
                        <div
                          v-if="selectedLogoOption === 1"
                          class="title-info d-flex align-center mt-5"
                        >
                          <img
                            class="title-info"
                            src="../assets/icons/info-icon.png"
                          />
                          <p class="title-info mb-0 ml-1">
                            Profile picture will be visible to Breaker19 buyers
                          </p>
                        </div>

                        <br v-if="selectedLogoOption === 1" />

                        <div
                          class="d-flex flex-column flex-sm-row align-start mt-3 align-sm-center justify-space-between"
                        >
                          <div
                            @click="discard()"
                            class="choose-img-btn d-flex align-center justify-center"
                          >
                            Discard Changes
                          </div>

                          <v-btn
                            @click="saveImg('blob')"
                            class="save-btn remove-letter-spacing white--text text-capitalize rounded-md font-weight-medium"
                            dense
                            dark
                            color="black"
                          >
                            Save
                          </v-btn>
                        </div>
                      </template>

                      <!-- ======================= CHANGE PASSWORD PART 0 ======================== -->
                      <template v-if="selectedPasswordOption === 0">
                        <div class="current-password-section">
                          <div class="password-txt">
                            <p class="mb-1">Current Password</p>
                            <v-text-field
                              v-model="password"
                              :append-icon="
                                show0
                                  ? 'mdi-eye-outline'
                                  : 'mdi-eye-off-outline'
                              "
                              class="forgot-input inter-font-family"
                              placeholder="Insert current password here"
                              solo
                              dense
                              flat
                              background-color="#F4F4F4"
                              :type="show0 ? 'text' : 'password'"
                              @click:append="show0 = !show0"
                            ></v-text-field>
                          </div>

                          <div @click="checkCurrentPassword()">
                            <v-btn
                              class="invite-btn white--text"
                              block
                              color="black"
                              >Continue
                            </v-btn>
                          </div>
                          <br />
                          <p>{{ currentPasswordMsg }}</p>
                        </div>
                      </template>

                      <!-- ======================= CHANGE PASSWORD PART 2 ======================== -->
                      <template v-if="selectedPasswordOption === 1">
                        <div class="password-txt mb-5">
                          <p class="mb-1">New Password</p>
                          <v-text-field
                            v-model="newPassword"
                            @input="comparePassword()"
                            :append-icon="
                              show1 ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
                            "
                            class="forgot-input"
                            placeholder="New password here"
                            solo
                            dense
                            flat
                            hideDetails="true"
                            background-color="#F4F4F4"
                            :type="show1 ? 'text' : 'password'"
                            @click:append="show1 = !show1"
                          ></v-text-field>

                          <div
                            class="password-strength-section d-flex mt-2"
                            :class="{
                              'justify-space-between': passwordStrength === 2,
                              'justify-start': passwordStrength === 1,
                            }"
                          >
                            <div
                              :class="{
                                'password-weak-strength':
                                  passwordStrength === 0,
                                'password-medium-strength':
                                  passwordStrength === 1,
                                'password-strong-strength':
                                  passwordStrength === 2,
                              }"
                            ></div>
                            <div
                              :class="{
                                'password-medium-strength ml-1':
                                  passwordStrength === 1,
                                'password-strong-strength':
                                  passwordStrength === 2,
                              }"
                            ></div>
                            <div
                              :class="{
                                'password-strong-strength':
                                  passwordStrength === 2,
                              }"
                            ></div>
                          </div>

                          <p class="mt-1 mb-1" v-if="passwordStrength >= 0">
                            Password strength:
                            <span
                              :class="{
                                'password-weak-txt': passwordStrength === 0,
                                'password-medium-txt': passwordStrength === 1,
                                'password-strong-txt': passwordStrength === 2,
                              }"
                            >
                              {{ passwordStrength === 0 ? "Weak" : "" }}
                              {{ passwordStrength === 1 ? "Medium" : "" }}
                              {{ passwordStrength === 2 ? "Strong" : "" }}
                            </span>
                          </p>

                          <p class="info-txt mb-1">{{ changePasswordMsg }}</p>
                        </div>

                        <div class="password-txt mb-5">
                          <p class="mb-1">Confirm New Password</p>
                          <v-text-field
                            v-model="confirmPassword"
                            @input="comparePassword()"
                            :append-icon="
                              show2 ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
                            "
                            class="forgot-input"
                            hideDetails="true"
                            placeholder="Confirm new password here"
                            solo
                            dense
                            flat
                            background-color="#F4F4F4"
                            :type="show2 ? 'text' : 'password'"
                            @click:append="show2 = !show2"
                          ></v-text-field>
                        </div>

                        <div>
                          <v-btn
                            @click="changePassword()"
                            class="invite-btn white--text"
                            block
                            color="black"
                            >Change Password
                          </v-btn>
                        </div>
                        <br />
                      </template>

                      <!-- ============================= CHANGE TIME ======================================-->
                      <!-- <template v-if="selectedTimeOption === 0">
                          <h4 class="mb-1">Change timezone</h4>

                          <div class="timezone">
                              <v-autocomplete v-model="userTimezone" @input="updateChangeStatus"
                                  :items="timezone" width="50%" :search-input.sync="searchTimezone"
                                  item-text="label" item-value="tzCode" flat auto-select-first
                                  outlined dense :rules="timeZoneRule">
                              </v-autocomplete>

                              <v-btn @click="updateTime()"
                                  class="save-btn remove-letter-spacing white--text text-capitalize rounded-md font-weight-medium"
                                  dense dark color="black">
                                  Update
                              </v-btn>
                          </div>
                      </template> -->
                    </div>
                  </v-col>
                </v-row>
              </div>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-dialog v-model="changesConfirmSpec" persistent max-width="440">
      <v-card class="pa-6 accept-order-vcard">
        <v-card-title
          class="reject-order-main-title pl-0 pb-1 pt-0 pr-0 d-flex justify-space-between"
        >
          Are you sure?
        </v-card-title>

        <div class="title-info d-flex align-center align-start mb-2 pl-0">
          <span class="pl-1 font-weight-bold accept-order-info">
            Changes made will be lost</span
          >
        </div>

        <v-card-actions class="pa-0">
          <v-spacer></v-spacer>
          <v-btn
            class="accept-order-btns rounded-md"
            width="50%"
            dense
            outlined
            text
            @click="changesConfirmSpec = false"
          >
            No
          </v-btn>

          <v-btn
            class="accept-order-btns white--text rounded-md"
            dense
            width="50%"
            dark
            color="black"
            @click="
              changesConfirmSpec = false;
              loadRoute();
            "
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="imageUploadSpec" persistent max-width="440">
      <v-card class="pa-6 accept-order-vcard">
        <v-card-title
          class="reject-order-main-title pl-0 pb-1 pt-0 pr-0 d-flex justify-space-between"
        >
          Use Recommended resolution?
        </v-card-title>

        <div class="title-info d-flex align-center align-start mb-2 pl-0">
          <span class="pl-1 font-weight-bold accept-order-info">
            The image you selected is smaller than the recommended resolution of
            720x144. If you proceed with this image, there's going to be a loss
            of quality.</span
          >
        </div>

        <v-card-actions class="pa-0">
          <v-spacer></v-spacer>
          <v-btn
            class="accept-order-btns rounded-md"
            width="50%"
            dense
            outlined
            text
            @click="
              imageUploadSpec = false;
              dontSelectImg();
            "
          >
            No
          </v-btn>

          <v-btn
            class="accept-order-btns white--text rounded-md"
            dense
            width="50%"
            dark
            color="black"
            @click="
              imageUploadSpec = false;
              selectImg();
            "
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- <AppLoading :active.sync="isLoading" /> -->
  </div>
</template>

<style scoped lang="scss">
.manageProfile-styles {
  @import "../assets/styles/ManageProfile.scss";

  font-family: "Outfit", sans-serif;

  .inter-font-family {
    font-family: "Inter", sans-serif !important;
  }

  .outfit-font-family {
    font-family: "Outfit", sans-serif !important;
  }
}
</style>

<script>
import axios from "axios";
import { phone } from "phone";
import timezones from "timezones-list";
import allPhoneCodes from "../assets/json/phone-codes.json";
import MobileHeader from "../components/mobile-header.vue";
import mixin from "../mixins/auth.mixin.js";

export default {
  name: "ManageProfile",
  components: {
    MobileHeader,
  },
  data: () => ({
    authMixins: mixin.methods,
    mOption: -1,
    selectedProfileOption: -1,
    selectedPasswordOption: -1,
    selectedLogoOption: -1,
    selectedTimeOption: -1,
    optionNo: -1,
    profileOptionNo: -1,
    show1: false,
    show2: false,
    show0: false,
    requestStatus: false,
    menuExpended: false,
    isLoading: true,
    isDirty: false,
    changesConfirmSpec: false,
    imageUploadSpec: false,
    password: "",
    passwordStrength: -1,
    newPassword: "",
    changePasswordMsg: "",
    confirmPassword: "",
    svgProfileFill: "black",
    svgPwdFill: "white",
    svgLogoFill: "black",
    clockColor: "black",
    mobilebgcolor: "white",
    timezone: timezones,
    searchTimezone: null,
    userTimezone: "America/Chicago",
    timeZoneRule: [(v) => !!v || "Timezone is required"],
    selectedImage: null,
    model: false,

    cropperViewport: { width: 150, height: 150, type: "circle" },
    cropperBoundries: { width: 300, height: 300 },
    cropperOutput: { type: "blob", format: "png", circle: true },
    show: true,
    userImages: {
      profile: "",
      logo: "",
    },
    currentPasswordMsg: "",
    userData: {},
    imgLoaded: false,
    zoomLevel: 1,
    currentScale: 1,
    imgSelected: null,
    cropUpdateExecuted: 0,
    selectedCode: "+1",
    dialCodes: [],
    mobileNo: "",
    phoneCodes: allPhoneCodes,
    errorPhoneMsg: "",
    userId: null,
    discardStatus: false,
  }),
  created() {
    this.dialCodes = this.phoneCodes.countries.map((country) => {
      return {
        value: country.code,
        text: country.code,
      };
    });
  },
  mounted() {
    document.title = "Carrier Portal - Breaker19.app";

    window.addEventListener("beforeunload", this.handleBeforeUnload);

    this.loadImage();
  },

  // filters: {
  //     formatToPhone(value) {
  //         const input = value.replace(/\D/g, '').substring(0, 10);
  //         const areaCode = input.substring(0, 3);
  //         const middle = input.substring(3, 6);
  //         const last = input.substring(6, 10);

  //         if (input.length > 6) { value = `(${areaCode}) ${middle}-${last}`; }
  //         else if (input.length > 3) { value = `(${areaCode}) ${middle}`; }
  //         else if (input.length > 0) { value = `(${areaCode}`; }

  //         return value;
  //     },
  // },

  methods: {
    enterPhone(value) {
      if (this.mobileNo === "")
        this.errorPhoneMsg = "Please enter your phone number";
      else this.errorPhoneMsg = "";

      this.isDirty = true;
      this.formatnAssignToPhone(value);
    },

    mouseOver(optionNo) {
      if (optionNo === 0) this.svgProfileFill = "white";
      else if (optionNo === 1) this.svgPwdFill = "white";
      else if (optionNo === 2) this.svgLogoFill = "white";
      else if (optionNo === 3) this.clockColor = "white";
    },
    mouseLeave(optionNo) {
      if (optionNo === 0) this.svgProfileFill = "black";
      else if (optionNo === 1) this.svgPwdFill = "black";
      else if (optionNo === 2) this.svgLogoFill = "black";
      else if (optionNo === 3) this.clockColor = "black";
    },

    checkNumber(event) {
      if (event.keyCode >= 49 && event.keyCode <= 56)
        if (event.shiftKey) event.preventDefault();

      if (
        event.keyCode === 189 ||
        event.keyCode === 187 ||
        event.keyCode === 192
      )
        event.preventDefault();

      let char = String.fromCharCode(event.keyCode);
      if (/^[A-Za-z]+$/.test(char)) event.preventDefault();
    },

    removeSpecialCharactersAndSpaces(str) {
      return str.replace(/[^a-zA-Z0-9]/g, "");
    },

    formatnAssignToPhone(value) {
      const input = value.replace(/\D/g, "").substring(0, 10);
      const areaCode = input.substring(0, 3);
      const middle = input.substring(3, 6);
      const last = input.substring(6, 10);

      if (input.length > 6) {
        value = `(${areaCode}) ${middle}-${last}`;
      } else if (input.length > 3) {
        value = `(${areaCode}) ${middle}`;
      } else if (input.length > 0) {
        value = `(${areaCode}`;
      }

      const regex = /^[0-9()\- ]+$/;
      const e164 = /^\+[1-9]\d{1,14}$/;

      if (
        phone(value).isValid === false ||
        e164.test(
          this.selectedCode +
            this.removeSpecialCharactersAndSpaces(this.mobileNo)
        ) === false
      ) {
        this.errorPhoneMsg = "Phone number invalid";
        this.validPhoneNo = false;
        this.mobileNo = value;
      } else {
        if (regex.test(value)) {
          this.mobileNo = value;
          this.validPhoneNo = true;
        } else {
          this.$nextTick(() => {
            this.mobileNo = "";
          });
        }
      }
    },

    formatToPhoneOnly(value) {
      const input = value.replace(/\D/g, "").substring(0, 10);
      const areaCode = input.substring(0, 3);
      const middle = input.substring(3, 6);
      const last = input.substring(6, 10);

      if (input.length > 6) {
        value = `(${areaCode}) ${middle}-${last}`;
      } else if (input.length > 3) {
        value = `(${areaCode}) ${middle}`;
      } else if (input.length > 0) {
        value = `(${areaCode}`;
      }

      return value;
    },

    update() {
      if (this.cropUpdateExecuted < 4) this.cropUpdateExecuted++;
      if (this.cropUpdateExecuted > 2) this.isDirty = true;
    },

    mobileNavSelection(optionNo) {
      this.mOption = optionNo;

      if (this.mOption === 3) this.clockColor = "white";
      else this.clockColor = "black";
    },

    saveRoute(routeUrl) {
      if (this.isDirty) {
        this.changesConfirmSpec = true;
        this.forwardRoute = routeUrl;
      } else this.$router.push(routeUrl);
    },

    loadRoute() {
      if (this.forwardRoute === "/manage-profile") {
        this.isDirty = false;

        if (this.optionNo === 0) {
          if (this.profileOptionNo === -1) this.profileOption(0);
          else this.profileOption(this.profileOptionNo);
        } else if (this.optionNo === 1) {
          this.passwordOption(0);
        } else if (this.optionNo === 2) {
          this.LogoOption(0);
        } else if (this.optionNo === 3) {
          this.updateTimeOption(0);
        } else this.profileOption(0);

        return;
      }
      this.$router.push(this.forwardRoute);
    },

    updateChangeStatus() {
      this.isDirty = true;
    },

    handleBeforeUnload(event) {
      if (this.isDirty) {
        const warningMessage =
          "You have unsaved changes! Are you sure you want to leave?";
        event.returnValue = warningMessage;
        return warningMessage;
      }
    },

    loadImage() {
      let config = {
        headers: {
          Authorization: localStorage.authToken,
        },
      };

      axios
        .get("auth/carrier/infos", config)
        .then((response) => {
          if (response.status === 200) {
            this.userData.name = `${response.data.first_name} ${response.data.last_name}`;
            this.userData.email = response.data.email;
            this.userData.country_code = response.data.country_code;
            this.userData.mobile_no = response.data.mobile_no;
            this.userData.time_zone = response.data.time_zone;
            this.userImages.profile =
              response.data.profile_url !== null
                ? response.data.profile_url
                : "";
            this.userImages.logo =
              response.data.company_logo_url !== null
                ? response.data.carrier.company_logo_url
                : "";

            this.userTimezone = this.userData.time_zone;
            this.userId = this.userData.id;

            this.mobileNo = this.userData.mobile_no
              ? this.formatToPhoneOnly(this.userData.mobile_no)
              : "";
            this.selectedCode = this.userData.country_code;
            localStorage.profileImg =
              response.data.profile_url !== null
                ? response.data.profile_url
                : "";
          }
          if (response.status === 401) {
            this.authMixins.clearAuthData();
            this.$router.push("/signin");
          }
          this.isLoading = false;
        })
        .catch(async (err) => {
          console.log(err);
          if (err.response && err.response.status === 401) {
            this.authMixins.clearAuthData();
            this.$router.push("/signin");
          }
          this.isLoading = false;
        });

      this.profileOption(0);
    },

    expandMenu() {
      this.menuExpended = !this.menuExpended;
      if (this.menuExpended) {
        this.mobilebgcolor = "transparent";
      } else this.mobilebgcolor = "white";
    },

    profileOption(optionNo) {
      this.selectedProfileOption = optionNo;
      if (this.selectedImage && this.discardStatus) {
        this.selectedImage = null;
        this.discardStatus = false;
        this.imgSelected = null;
        this.isDirty = false;

        this.$refs.cropper.bind({
          url: this.userImages.profile,
        });

        this.$refs.fileInput.value = "";
      }

      if (this.isDirty) {
        this.optionNo = 0;
        this.profileOptionNo = optionNo;
        this.changesConfirmSpec = true;
        this.forwardRoute = "/manage-profile";
        return;
      }

      this.selectedPasswordOption = -1;
      this.selectedLogoOption = -1;
      this.selectedTimeOption = -1;

      this.imgLoaded = false;

      if (this.selectedProfileOption === 1) {
        this.mobileNo = this.userData.mobile_no
          ? this.formatToPhoneOnly(this.userData.mobile_no)
          : "";
        this.errorPhoneMsg = "";

        setTimeout(() => {
          if (this.userImages.profile) {
            this.$refs.cropper.bind({
              url: this.userImages.profile,
            });
          }
        }, 500);

        this.cropperViewport.width = 150;
        this.cropperViewport.height = 150;
        this.cropperViewport.type = "circle";

        this.cropperBoundries.width = 300;
        this.cropperBoundries.height = 300;
      }

      if (this.selectedProfileOption === 0) {
        if (this.userData && this.mobileNo) {
          this.mobileNo = this.userData.mobile_no
            ? this.formatToPhoneOnly(this.userData.mobile_no)
            : "";
          this.errorPhoneMsg = "";
        }
        this.discard();
      }
    },

    passwordOption(optionNo) {
      if (this.isDirty) {
        this.optionNo = 1;
        this.changesConfirmSpec = true;
        this.forwardRoute = "/manage-profile";
        return;
      }

      this.selectedPasswordOption = optionNo;
      this.selectedProfileOption = -1;
      this.selectedLogoOption = -1;
      this.selectedTimeOption = -1;

      if (this.selectedPasswordOption === 0) {
        this.mobileNo = this.userData.mobile_no
          ? this.formatToPhoneOnly(this.userData.mobile_no)
          : "";
        this.errorPhoneMsg = "";
      }

      if (this.selectedPasswordOption === 0) this.discard();

      if (
        this.selectedPasswordOption === 0 &&
        localStorage.impersonationToken
      ) {
        this.passwordOption(1);
      }
    },

    LogoOption(optionNo) {
      if (this.selectedImage && this.discardStatus) {
        this.selectedImage = null;
        this.discardStatus = false;
        this.imgSelected = null;
        this.isDirty = false;

        this.$refs.cropper.bind({
          url: this.userImages.logo,
        });

        this.$refs.fileInput.value = "";
      }

      if (this.isDirty) {
        this.optionNo = 2;
        this.changesConfirmSpec = true;
        this.forwardRoute = "/manage-profile";
        return;
      }

      this.selectedLogoOption = optionNo;
      this.selectedProfileOption = -1;
      this.selectedPasswordOption = -1;
      this.selectedTimeOption = -1;

      this.imgLoaded = false;

      if (this.selectedLogoOption === 1) {
        this.cropperViewport.width = 720;
        this.cropperViewport.height = 144;
        this.cropperViewport.type = "square";

        this.cropperBoundries.width = 780;
        this.cropperBoundries.height = 166;

        setTimeout(() => {
          this.$refs.cropper.bind({
            url: this.userImages.logo,
          });
        }, 500);
      }

      if (this.selectedLogoOption === 0) {
        this.mobileNo = this.userData.mobile_no
          ? this.formatToPhoneOnly(this.userData.mobile_no)
          : "";
        this.errorPhoneMsg = "";
        this.discard();
      }
    },

    updateTimeOption(optionNo) {
      if (this.isDirty) {
        this.optionNo = 3;
        this.changesConfirmSpec = true;
        this.forwardRoute = "/manage-profile";
        return;
      }

      this.selectedTimeOption = optionNo;

      this.selectedLogoOption = -1;
      this.selectedProfileOption = -1;
      this.selectedPasswordOption = -1;
    },

    updateTime() {
      let config = {
        headers: {
          Authorization: localStorage.authToken,
        },
      };

      let timezone = {
        time_zone: this.userTimezone,
      };

      axios
        .patch("auth/carrier/timezone", timezone, config)
        .then((response) => {
          if (response.status === 200) {
            this.isDirty = false;
            this.$toast.success("Timezone updated successfully", {});

            localStorage.timezone = this.userTimezone;
          }
          this.requestStatus = false;
        })
        .catch(async (err) => {
          console.log(err);
          this.requestStatus = false;
        });
    },

    updatePhone() {
      if (!this.requestStatus) {
        this.requestStatus = true;

        let userData = {
          id: this.userId,
          phone_number: +this.removeSpecialCharactersAndSpaces(this.mobileNo),
          country_code: this.selectedCode,
        };

        let config = {
          headers: {
            Authorization: localStorage.authToken,
          },
        };

        axios
          .post(`auth/carrier/update-phone`, userData, config)
          .then((response) => {
            if (response.status === 200 || response.status === 201) {
              this.isDirty = false;
              this.userData.mobile_no = userData.phone_number + "";
              this.mobileNo = this.formatToPhoneOnly(
                userData.phone_number + ""
              );
              this.selectedCode = userData.country_code + "";
              this.$toast.success("Phone number updated successfully", {});
            } else this.$toast.error("Phone number update failed", {});

            this.requestStatus = false;
          })
          .catch(async (err) => {
            console.log(err);
            this.requestStatus = false;
            if (err.response) this.$toast.error(err.response.data.message, {});
          });
      }
    },

    blobToFile(blob, fileName) {
      return new File([blob], fileName, {
        type: blob.type,
        lastModified: Date.now(),
      });
    },

    checkImg(event, num) {
      this.imgSelected = {
        e: event,
        num: num,
      };

      let files = event.target.files || event.dataTransfer.files;
      if (!files.length) return;

      if (files) {
        this.selectedImage = files;
        let readerUrl = new FileReader();

        readerUrl.onload = (e) => {
          let data = e.target.result;
          const img = new Image();

          img.onload = () => {
            if (
              this.selectedLogoOption === 1 &&
              (img.width < 720 || img.height < 144)
            ) {
              console.log("Wrong Image dimension");
              this.imageUploadSpec = true;
            } else {
              this.$refs.cropper.bind({
                url: e.target.result,
              });
            }
          };
          img.src = data;
        };
        readerUrl.readAsDataURL(files[0]);
      }
    },

    dontSelectImg() {
      this.imgSelected = null;
      this.$refs.fileInput.value = "";
      if (this.selectedImage) this.selectedImage = null;
      this.isDirty = false;
    },

    selectImg() {
      let files =
        this.imgSelected.e.target.files ||
        this.imgSelected.e.dataTransfer.files;
      if (!files.length) return;

      this.selectedImage = files;

      if (
        !/\.(gif|jpg|jpeg|png|bmp|GIF|JPG|PNG)$/.test(
          this.imgSelected.e.target.value
        )
      ) {
        alert(".gif,jpeg,jpg,png,bmp");
        return false;
      }
      let reader = new FileReader();
      this.isDirty = true;

      reader.onload = (e) => {
        this.$refs.cropper.bind({
          url: e.target.result,
        });
      };
      reader.readAsDataURL(files[0]);
    },

    cropping() {
      this.isDirty = true;
    },

    discard() {
      if (this.isDirty) {
        this.changesConfirmSpec = true;
        this.forwardRoute = "/manage-profile";
      } else {
        if (this.selectedLogoOption > 0) {
          this.LogoOption(0);
        } else if (this.selectedProfileOption > 0) {
          this.profileOption(0);
        }
      }

      this.discardStatus = true;
    },

    saveImg() {
      if (this.requestStatus) return;
      if (
        (!this.imgSelected || !this.$refs.fileInput.value) &&
        this.userData.profile_url === ""
      ) {
        this.$toast.info("Upload a new image", {});
        return;
      }

      this.requestStatus = true;
      const formData = new FormData();
      let httpPath = "";

      if (this.selectedProfileOption === 1) this.cropperOutput.circle = true;
      else if (this.selectedLogoOption === 1) this.cropperOutput.circle = false;

      this.$refs.cropper.result(this.cropperOutput, (output) => {
        let cropped = output;
        const ext = "png";

        if (this.selectedProfileOption === 1) {
          httpPath = "auth/carrier/update-profile-pic";

          if (this.imgSelected)
            formData.append(
              "profile",
              cropped,
              this.imgSelected.e.target.files[0].name
            );
          else {
            let file = this.blobToFile(
              cropped,
              `profile_crpl_${makeId(16)}.${ext}`
            );
            formData.append("profile", cropped, file.name);
          }
        } else if (this.selectedLogoOption === 1) {
          httpPath = "auth/carrier/update-company-logo";

          if (this.imgSelected)
            formData.append(
              "company_logo",
              cropped,
              this.imgSelected.e.target.files[0].name
            );
          else {
            let file = this.blobToFile(
              output,
              `logo_crpl_${makeId(16)}.${ext}`
            );
            formData.append("company_logo", cropped, file.name);
          }
        }

        let config = {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: localStorage.authToken,
          },
        };

        axios
          .post(httpPath, formData, config)
          .then((response) => {
            if (response.status === 200) {
              this.$toast.success("Image uploaded successfully", {});

              this.$set(
                this.userImages,
                "profile",
                response.data.data.profile_url
              );
              this.$set(
                this.userImages,
                "logo",
                response.data.data.company_logo_url
              );
              this.isDirty = false;

              // let _tempUserdata = {
              //   ...localStorage.udata,
              //   'company_logo_url': response.data.data.company_logo_url,
              //   'profile_url': response.data.data.profile_url,
              // }
              // localStorage.udata = JSON.stringify(_tempUserdata);
              this.loadImage();
              if (this.selectedProfileOption === 1) this.profileOption(0);
              else if (this.selectedLogoOption === 1) this.LogoOption(0);
            }

            this.requestStatus = false;
          })
          .catch(async (err) => {
            console.log(err);
            this.requestStatus = false;
            if (err.response && err.response.status === 422) {
              this.$toast.error("Image upload failed", {});
            }
          });
      });
    },

    checkCurrentPassword() {
      let config = {
        headers: {
          Authorization: localStorage.authToken,
        },
      };

      let passwordData = {
        current_password: this.password,
      };

      axios
        .post("auth/carrier/current-password", passwordData, config)
        .then((response) => {
          if (response.status === 200 && response.data.data.is_password) {
            this.passwordOption(1);
            this.isDirty = true;
          } else if (response.status === 422) {
            this.$toast.error("Incorrect current password", {});
          }
          this.requestStatus = false;
        })
        .catch(async (err) => {
          console.log(err);
          this.requestStatus = false;
          if (err.response.status === 422) {
            this.$toast.error("Incorrect current password", {});
          }
        });
    },

    comparePassword() {
      if (+this.newPassword.length <= 5) {
        this.passwordStrength = 0;
        this.changePasswordMsg =
          "Please create a password with minimum length of nine characters.";
      }
      if (+this.newPassword.length > 5 && +this.newPassword.length <= 8) {
        this.passwordStrength = 1;
        this.changePasswordMsg =
          "Please create a password with minimum length of nine characters.";
      }
      if (+this.newPassword.length > 8) {
        this.passwordStrength = 2;
      }

      if (
        +this.newPassword.length > 5 &&
        this.confirmPassword !== this.newPassword
      ) {
        this.changePasswordMsg = "Passwords do not match.";
      } else {
        if (+this.newPassword.length <= 5) {
          this.changePasswordMsg =
            "Please create a password with minimum length of nine characters.";
        } else this.changePasswordMsg = "";
      }
    },

    changePassword() {
      if (+this.newPassword.length === 0) {
        this.passwordStrength = -1;
        this.changePasswordMsg =
          "Please create a password with minimum length of nine characters.";
        return;
      }
      if (+this.newPassword.length <= 5) {
        this.passwordStrength = 0;
        this.changePasswordMsg =
          "Please create a password with minimum length of nine characters.";
        return;
      }
      if (+this.newPassword.length > 5 && +this.newPassword.length < 8) {
        this.passwordStrength = 1;
        return;
      }
      if (
        +this.newPassword.length > 5 &&
        this.confirmPassword !== this.newPassword
      ) {
        this.changePasswordMsg = "Passwords do not match.";
        return;
      }

      let config = {
        headers: {
          Authorization: localStorage.authToken,
        },
      };

      let passwordData = {
        password: this.newPassword,
        password_confirmation: this.newPassword,
      };

      this.changePasswordMsg = "";
      axios
        .patch("auth/carrier/change-password", passwordData, config)
        .then((response) => {
          if (response.status === 200) {
            this.$toast.success("Password changed successfully", {});
            this.isDirty = false;
            this.selectedPasswordOption = 0;
            this.password = "";
            this.newPassword = "";
            this.confirmPassword = "";
          }
          this.requestStatus = false;
        })
        .catch(async (err) => {
          console.log(err);
          this.requestStatus = false;
        });
    },
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.updateWindowWidth);
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
  },
};

function makeId(length) {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;

  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }

  return result;
}
</script>
