import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import store from "./store";

import "./assets/styles/index.scss";
import axios from "axios";
// import * as VueGoogleMaps from "vue2-google-maps"
import VueZoomer from "vue-zoomer";
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-bootstrap.css";

import "vue-loading-overlay/dist/vue-loading.css";
import Loading from "vue-loading-overlay";
import LogRocket from "logrocket";
import VueCroppie from "vue-croppie";
import "croppie/croppie.css";
import * as Sentry from "@sentry/vue";
import mixin from "./mixins/auth.mixin.js";
if (process.env.VUE_APP_LOG_ROCKET) {
  LogRocket.init(process.env.VUE_APP_LOG_ROCKET);
}

Vue.component("AppLoading", Loading);
Vue.config.productionTip = false;

// "https://dev.api.breaker19.app/api/v1/"
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
axios.defaults.headers.common.Authorization = localStorage.getItem("token");

const impersonationToken = localStorage.getItem("impersonationToken");

if (impersonationToken) {
  axios.defaults.headers.common["X-Impersonate"] = impersonationToken;
}

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      mixin.methods.clearAuthData();
      if (router.currentRoute.path === "/signin") return;
      router.push({ name: "signin" });
    }
    return Promise.reject(error);
  }
);

Vue.use(VueCroppie);
Vue.use(VueZoomer);
Vue.use(VueToast);

// Vue.use(VueGoogleMaps, {
//   load: {
//     key: process.env.VUE_APP_GOOGLE_MAP,
//     libraries: "places,drawing,visualization"
//   }
// });

if (
  process.env.VUE_APP_SENTRY_ENVIRONMENT &&
  process.env.VUE_APP_SENTRY_ENVIRONMENT != "local"
) {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    environment: process.env.VUE_APP_SENTRY_ENVIRONMENT,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: [process.env.VUE_APP_PROJECT_URL, /^\//],
      }),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
